<template>
  <div class="defaultLayout" :class="{ alert: alert }">
    <in-header :class="{ isFixed : isFixed }" />
    <div class="container" ref="scrollTest">
      <in-nav />
      <in-main :currentMenu="currentMenu"/>
    </div>
    <PTApply></PTApply>
    <SubPointBuy></SubPointBuy>
    <loading></loading>
  </div>
</template>

<script>
import InHeader from '@/components/main/header'
import InNav from '@/components/main/Nav'
import InMain from '@/components/main/Main'
import Loading from '@/components/common/Loading.vue'

import PTApply from '@/components/common/PTApply.vue'
import SubPointBuy from '@/components/common/SubPointBuy.vue'
export default {
  name: 'DefaultLayout',
  components: {
    PTApply,
    SubPointBuy,
    InHeader,
    InNav,
    InMain,
    Loading
  },
  data () {
    return {
      isFixed: false,
      menuList: [],
      currentMenu: {
        isActive: true,
        parent: {},
        child: {}
      }
    }
  },
  mounted() {
    document.addEventListener('scroll', this.scroll)
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.scroll)
  },
  methods: {
    scroll () {
      if (window.scrollY > this.$refs.scrollTest.offsetTop) {
        this.isFixed = true
      } else {
        this.isFixed = false
      }
   }
  },
  async created () {
    await this.setGameList()
  }
}
</script>

<style scoped>
.container {
  display: flex;
  height: 100%;
  width: 100%;
}
.alert {animation: blink .5s step-end infinite;}
@keyframes blink {
 0% {background-color: #ff9797;}
 50% {background-color: #f7bcbc;}
}
@-webkit-keyframes blink {
 0% {background-color: #ff9797;}
 50% {background-color: #f7bcbc;}
}
</style>
