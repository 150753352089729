<template>
  <iframe src="/silence.mp3" allow="autoplay" id="audio" style="display:none" />

  <audio ref="memJoinReqCnt"  autoplay loop muted id="memJoinReqCnt" >
    <source src="@/assets/media/join.mp3" type="audio/wav" />
  </audio>
  <audio ref="cashInReqCnt"  autoplay loop  muted id="cashInReqCnt" >
    <source src="@/assets/media/charge.mp3" type="audio/wav" />
  </audio>
  <audio ref="cashOutReqCnt"  autoplay loop muted id="cashOutReqCnt" >
    <source src="@/assets/media/exchange.mp3" type="audio/wav" />
  </audio>
  <audio ref="creditCnt"  autoplay loop muted id="creditCnt">
    <source src="@/assets/media/credit.mp3" type="audio/wav" />
  </audio>
  <audio ref="creditExchangeCnt"  autoplay loop muted id="creditExchangeCnt">
    <source src="@/assets/media/credit.mp3" type="audio/wav" />
  </audio>
  <audio ref="qnaReqCnt"  autoplay loop muted id="qnaReqCnt" >
    <source src="@/assets/media/qna.mp3" type="audio/wav" />
  </audio>
  <audio ref="insuranceCnt"  autoplay loop muted id="insuranceCnt" >
    <source src="@/assets/media/insure.mp3" type="audio/wav" />
  </audio>
  <audio ref="insuranceExchangeCnt"  autoplay loop muted id="insuranceExchangeCnt" >
    <source src="@/assets/media/insure.mp3" type="audio/wav" />
  </audio>

  <div class="alertBox" v-if="isNotice">
    <a @click="isNotice = !isNotice"><i class="fa fa-times" aria-hidden="true"></i></a>
    <div>
      {{ $t("txt.notification") }}
      <a>
        <h4>{{ noticeField }}</h4>
        <span class="text">{{ $t("common.request") }} ( <em>{{noticeCount}}</em> )</span>
      </a>
    </div>
  </div>
  <div class="alertLink" v-if="noticeCount">
     <div class="alertLinkWrap">
        <div class="alertLinkIn">
           <div class="alertToLinkBox alertin" v-if="model.cashInReqCnt">
            <router-link :to="{ name: 'userInApply', query: { status: 'all', time: new Date().getTime() } }">
              <img src="@/assets/img/icon_bell.png">
              <span class="vertical-txt">{{ $t("button.reDeposit") }}<em>{{model.cashInReqCnt}}</em>{{ $t("common.case") }}</span>
            </router-link>
           </div>

           <div class="alertToLinkBox alertout" v-if="model.cashOutReqCnt">
            <router-link :to="{ name: 'userOutApply', query: { status: 'all', time: new Date().getTime() } }">
              <img src="@/assets/img/icon_bell.png">
              <span class="vertical-txt">{{ $t("button.outAppply") }}<em>{{model.cashOutReqCnt}}</em>{{ $t("common.case") }}</span>
            </router-link>
           </div>

           <div class="alertToLinkBox alertjoin" v-if="model.memJoinReqCnt">
            <router-link :to="{ name: 'userSignUpList', query: { status: 'all', time: new Date().getTime() } }">
              <img src="@/assets/img/icon_bell.png">
              <span class="vertical-txt">{{ $t("common.registera") }}<em>{{model.memJoinReqCnt}}</em>{{ $t("common.case") }}</span>
            </router-link>
           </div>

           <div class="alertToLinkBox alertqna" v-if="model.qnaReqCnt">
            <router-link :to="{ name: 'boardQna', query: { status: 'all', time: new Date().getTime() } }">
              <img src="@/assets/img/icon_bell.png">
              <span>1:1<span class="vertical-txt">{{ $t("table.head.qApply") }}<em>{{model.qnaReqCnt}}</em>{{ $t("common.case") }}</span></span>
            </router-link>
           </div>
        </div>
     </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'NotificationAlarm',
  props: [
    'headInfo'
  ],
  data () {
    return {
      audio: {},
      isNotice: false,
      noticeField: '',
      noticeCount: 0,
      cashInCount: 0,
      model: {}
    }
  },
  mounted () {
    let AudioContext
    let audioContext

    window.onload = function () {
      navigator.mediaDevices.getUserMedia({ audio: true }).then(() => {
        AudioContext = window.AudioContext || window.webkitAudioContext
        audioContext = new AudioContext()
      }).catch(e => {
        console.error(`Audio permissions denied: ${e}`)
      })
    }
  },
  watch: {
    alarmInfo: {
      handler (model, oldModel) {
        if (!oldModel) return
        // const bellKeys = ["memberApply", "inApply", "outApply", "boardCnt", "biSummaryApplyCnt"];
        const bellKeys = ['qnaReqCnt', 'cashInReqCnt', 'cashOutReqCnt', 'memJoinReqCnt', 'insuranceCnt', 'insuranceExchangeCnt', 'creditCnt', 'creditExchangeCnt']

        let trigger = false

        this.model = { ...model }

        bellKeys.forEach((key, idx) => {
          if (this.$refs[key]) {
            this.$refs[key].muted = true

            if (model[key] && model[key] > 0) {
              this.$refs[key].muted = false
              this.$refs[key].volume = 1.0

              this.noticeCount = model[key]
              // this.isNotice = true

              if (key === 'cashInReqCnt') {
                this.noticeField = this.$t('common.deposit')
              }
              if (key === 'cashOutReqCnt') {
                this.noticeField = this.$t('common.withdraw')
              }
              if (key === 'memJoinReqCnt') {
                this.noticeField = this.$t('common.register')
              }
              if (key === 'qnaReqCnt') {
                this.noticeField = this.$t('common.qna2')
              }
              if (key === 'creditCnt') {
                this.noticeField = '알'
              }
              if (key === 'insuranceCnt') {
                this.noticeField = this.$t('common.bibet')
              }

              const audio = document.querySelector('#' + key).play()
              if (audio !== undefined) {
                audio.then(_ => {
                }).catch(error => {
                })
              }
              trigger = true
            }
          }
        })

        // const bodyClass = document.body.className
        // if (trigger) {
        //   if (bodyClass.indexOf('alarm') === -1) {
        //     document.body.className = bodyClass + ' alarm'
        //   }
        // } else {
        //   if (bodyClass.indexOf('alarm') > -1) {
        //     document.body.className = bodyClass.replace('alarm', '').replace(/\s/g, '')
        //   }
        // }

        const header = document.querySelector('header')
        const headerClass = header.className
        if (trigger) {
          if (headerClass.indexOf('alarm') === -1) {
            header.className = headerClass + ' alarm'
          }
        } else {
          if (headerClass.indexOf('alarm') > -1) {
            header.className = headerClass.replace('alarm', '').replace(/\s/g, '')
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapState([
      'alarmInfo'
    ]),
    ...mapGetters([
      'headCashInfo'
    ])
  }
}
</script>

<style scoped>
.notification-container {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 1600px;
  justify-content: space-between;
  padding-right: 10px;
  box-sizing: border-box;
  margin: 10px 17px;
}
.notification-wrap {
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 10px;
}
.noti-title {
  font-size: 13px;
  color: #ededed;
  font-weight: 800;
  margin-right: 10px;
}
.noti-title span {
  white-space: nowrap;
}
.noti-title span:last-child {
  margin-left: 5px;
}
.noti-item {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 6px;
  height: 25px;
  border-radius: 3px;
  color: #666;
  margin-right: 3px;
  user-select: none;
  border: 1px solid #bfbfbf;
}
.noti-item a {
  color: #666;
}
.noti-item a span span {
  color: #e95d50;
  font-weight: bold;
}
.noti-item a:first-child span span {
  color: #18698b;
}
.noti-item .text {
  margin-right: 2px;
}
.title-box {
  margin-right: 10px;
}
.contents-box {
  margin-right: 6px;
}
.contents-box.title {
  font-weight: 700;
}
.contents-box:last-child {
  margin: 0;
}

.content-mono {
  margin-right: 4px;
}
a {
  color: #fff;
}
a:hover {
  font-weight: 800;
}

.alertBox {width: 400px;height: 300px;position: fixed;left: 50%;top: 50%;transform: translate(-50%, -50%);background: #353535;color: #fff;display: flex;flex-direction: column;flex-wrap: wrap;}
.alertBox > a {display: flex;align-items: center;justify-content: flex-end;margin: 20px 20px 0 0;font-size: 30px;color: #fff;cursor: pointer;}
.alertBox > div {height: calc(100% - 50px);display: flex;align-items: center;justify-content: center;flex-direction: column;gap: 20px;font-size: 20px;}
.alertBox > div > a {display: flex;gap: 20px;color: #9b9b9b;align-items: center;}
.alertBox h4 {width: 70px;height: 30px;display: flex;align-items: center;justify-content: center;background: #000;color: #fff;font-size: 16px;}
.alertBox > div > a em {color: #ff3c3c;font-size: 25px;}

.alertLink {z-index: 9999;position: relative;}
.alertLinkWrap {position: absolute;top: 0;}
.alertLinkIn {display: flex;flex-direction: column;gap: 10px;}
.alertToLinkBox {width: 35px;height: 200px;display: flex; justify-content: center; align-items: center; color: #fff; padding: 5px 10px; box-sizing: border-box; border-radius: 0 6px 6px 0;box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.3);font-size: 14px;text-align: center;}
.alertin {background: #627afc;}
.alertout {background: #ff5de8;}
.alertjoin {background: #d46933;}
.alertqna {background: #5ec85a;}
.alertToLinkBox a {cursor: pointer; display: flex; align-items: center;flex-direction: column;gap: 10px;}
.alertToLinkBox em {margin: 3px 0;}
.vertical-txt {writing-mode: vertical-rl;text-orientation: upright;}
</style>
