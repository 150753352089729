import httpClient from '@/libs/http-client'

// 파트너 상세 정보
export function insureList (data) {
  const url = '/api/admin/partner/insured/list'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 상태변경
export function insureStatusUpdate (data) {
  const url = '/api/admin/partner/insured/statusUpdate'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 정산신청내역
export function insureHistory (data) {
  const url = '/api/admin/partner/insured/history'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 정산 하부 신청내역
export function insureDownHistory (data) {
  const url = '/api/admin/partner/insured/DownHistory'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 정산신청
export function insureApply (data) {
  const url = '/api/admin/partner/insured/apply'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 지급신청
export function insuredApply (data) {
  const url = '/api/admin/insured/request'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 컴퍼니 보험금 내 요청내역
export function insuredApplyList (data) {
  const url = '/api/admin/insured/request/myList'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 컴퍼니 보험금 하부 요청내역
export function insuredSubApplyList (data) {
  const url = '/api/admin/insured/request/underList'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 컴퍼니 보험금 신청상태 변경
export function insuredStatusChange (data) {
  const url = '/api/admin/insured/updateStatus'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 상부 보험 베팅내역
export function insureBetTopList (data) {
  const url = '/api/admin/insured/bet/topList'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 하부 보험 베팅내역
export function insureBetBotList (data) {
  const url = '/api/admin/insured/bet/botList'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 하부 보험 베팅내역
export function insureBetRealBotList (data) {
  const url = '/api/admin/insured/bottomBetlist'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function insureBetRealBotDetail (data) {
  const url = '/api/admin/insured/bottomBetDetail'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 알정산
export function creatReport (data) {
  const url = '/api/admin/credit/daily'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function getInsurePushList (data) {
  const url = '/api/admin/member/insuredPushInfo'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function saveInsurePush (data) {
  const url = '/api/admin/member/insuredPushSave'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function insuredReport (data) {
  const url = '/api/admin/insured/report'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function insuredIn (data) {
  const url = '/api/admin/insured/topDeposit'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function insuredOut (data) {
  const url = '/api/admin/insured/topWithdraw'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
