export default {
  name: 'ptCredit',
  path: '/pt/credit',
  component: () => import('@/views/member/pt/pages/credit'),
  redirect: {
    name: 'companyCreditApply'
  },
  meta: {
    layout: 'default',
    isActive: false,
    text: '알 관리',
    icon: 'fas fa-egg',
    code: 'LV050000'
  },
  children: [
    {
      name: 'ptCreditApply',
      path: '/pt/credit/apply',
      component: () => import('@/views/member/pt/pages/credit/apply'),
      meta: {
        menuMember: true,
        text: '알 신청내역',
        done: true,
        code: 'LV050100'
      }
    },
    {
      name: 'ptCreditExchange',
      path: '/pt/credit/exchange',
      component: () => import('@/views/member/pt/pages/credit/exchange'),
      meta: {
        menuMember: true,
        text: '알 환전내역',
        done: true,
        code: 'LV050200'
      }
    },
    {
      name: 'useReport',
      path: '/pt/credit/useReport',
      component: () => import('@/views/member/pt/pages/credit/useReport'),
      meta: {
        menuMember: true,
        text: '알 정산내역',
        done: true,
        code: 'LV050700'
      }
    },    
    {
      name: 'ptCreditSubPointList',
      path: '/pt/credit/pointList',
      component: () => import('@/views/member/pt/pages/credit/pointList'),
      meta: {
        menuMember: true,
        text: '알 처리내역',
        done: true,
        code: 'LV050300'
      }
    },
    {
      name: 'ptCrediSubPointListDaily',
      path: '/pt/credit/subPTcal',
      component: () => import('@/views/member/pt/pages/credit/subPTcal'),
      meta: {
        menuMember: true,
        text: '알 서브포인트 정산',
        done: true,
        code: 'LV050600'
      }
    },
    {
      name: 'ptCreditsubPt',
      path: '/pt/credit/subPt',
      component: () => import('@/views/member/pt/pages/credit/subPt'),
      meta: {
        menuMember: true,
        text: '서브포인트 충환전내역',
        done: true,
        code: 'LV050500'
      }
    },
    {
      name: 'subCredit',
      path: '/company/subCredit',
      component: () => import('@/views/member/company/pages/subCredit'),
      redirect: {
        name: 'companyCreditApply'
      },
      meta: {
        menuMember: true,
        text: '하부 하부업체 알 관리',
        isActive: false,
        exceptType: 'agency',
        code: 'LV050400'
      },
      children: [
        {
          name: 'subCreditApply',
          path: '/company/subCredit/apply',
          component: () => import('@/views/member/company/pages/subCredit/apply'),
          meta: {
            menuMember: true,
            text: '알 신청내역',
            done: true,
            code: 'LV050401'
          }
        },
        {
          name: 'subCreditExchange',
          path: '/company/subCredit/exchange',
          component: () => import('@/views/member/company/pages/subCredit/exchange'),
          meta: {
            menuMember: true,
            text: '알 환전내역',
            done: true,
            code: 'LV050402'
          }
        },
        {
          name: 'subCreditsubPt',
          path: '/company/subCredit/subPt',
          component: () => import('@/views/member/company/pages/subCredit/subPt'),
          meta: {
            menuMember: true,
            text: '서브포인트 충환전',
            done: true,
            code: 'LV050404'
          }
        },
        {
          name: 'subCreditPointList',
          path: '/company/subCredit/pointList',
          component: () => import('@/views/member/company/pages/subCredit/pointList'),
          meta: {
            menuMember: true,
            text: '서브포인트 적립내역',
            done: true,
            code: 'LV050403'
          }
        }
      ]
    }
  ]
}
