export default {
  name: 'Game',
  path: '/Game',
  component: () => import('@/views/member/settings/pages/game'),
  redirect: {
    name: 'CasinoSettings'
  },
  meta: {
    layout: 'default',
    isActive: false,
    text: '게임관리',
    icon: 'fas fa-egg',
    code: 'LV120000'
    // menuMember: true,
  },
  children: [
    {
      name: 'CasinoSettings',
      path: '/settings/game/casino',
      component: () => import('@/views/member/bet/pages/casino/settings'),
      meta: {
        menuMember: true,
        text: '카지노게임 설정관리',
        code: 'LV120100'
      }
    },
    {
      name: 'SlotSettings',
      path: '/settings/game/slot',
      component: () => import('@/views/member/bet/pages/slot/settings'),
      meta: {
        menuMember: true,
        text: '슬롯게임 설정관리',
        code: 'LV120200'
      }
    },
    {
      name: 'SportSettings',
      path: '/settings/game/sport',
      component: () => import('@/views/member/bet/pages/sport/settings'),
      meta: {
        menuMember: true,
        text: '스포츠게임 설정관리',
        code: 'LV120300'
      }
    },
    {
      name: 'MiniSettings',
      path: '/settings/game/mini',
      component: () => import('@/views/member/bet/pages/minigame/settings'),
      meta: {
        menuMember: true,
        text: '미니게임 설정관리',
        code: 'LV120400'
      }
    }
  ]
}