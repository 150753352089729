export default {
  name: 'admin',
  path: '/admin',
  component: () => import('@/views/member/admin'),
  redirect: {
    name: 'AdminList'
  },
  meta: {
    layout: 'default',
    isActive: false,
    text: '관리자 환경설정',
    icon: 'fas fa-user-cog',
    code: "LV110000"
  },
  children: [
    {
      name: 'AdminList',
      path: '/admin/list',
      component: () => import('@/views/member/admin/pages/list'),
      meta: {
        menuMember: true,
        text: '관리자 리스트',
        done: true,
        code: "LV110100"
      }
    },
    // TODO
    // {
    //   name: 'AdminBlackList',
    //   path: '/admin/blackList',
    //   component: () => import('@/views/member/admin/pages/blackList'),
    //   meta: {
    //     menuMember: true,
    //     text: '블랙리스트 관리'
    //   }
    // },
    // {
    //   name: 'AdminPermissionGroup',
    //   path: '/admin/permissionGroup',
    //   component: () => import('@/views/member/admin/pages/permissionGroup'),
    //   meta: {
    //     menuMember: true,
    //     text: '관리자 권한 그룹'
    //   }
    // },
    {
      name: 'AdminWhiteListIP',
      path: '/admin/whiteList_ip',
      component: () => import('@/views/member/admin/pages/whiteList_ip'),
      meta: {
        menuMember: true,
        text: '관리자 접속 아이피',
        code: "LV110200"
      }
    },
    {
      name: 'userList_ip',
      path: '/admin/userList_ip',
      component: () => import('@/views/member/admin/pages/userList_ip'),
      meta: {
        menuMember: true,
        text: '유저페이지 접속 IP차단',
        code: ""
      }
    },
    {
      name: 'AccBalance',
      path: '/settings/account/balance',
      component: () => import('@/views/member/settings/pages/account/balance'),
      meta: {
        menuMember: true,
        text: '관리자 현황',
        isActive: false,
        code: 'LV110300'
      }
    },
    {
      name: 'Acclog',
      path: '/settings/account/log',
      component: () => import('@/views/member/settings/pages/account/log'),
      meta: {
        menuMember: true,
        text: '관리자 로그',
        isActive: false,
        code: 'LV110400'
      }
    }
  ]
}
