export default {
  name: 'company',
  path: '/company',
  component: () => import('@/views/member/company'),
  redirect: {
    name: 'companyList'
  },
  meta: {
    layout: 'default',
    isActive: false,
    text: '하부업체 관리',
    icon: 'fas fa-handshake',
    exceptType: 'agency',
    code: "LV030000"
  },
  children: [
    {
      name: 'companyList',
      path: '/company/list',
      component: () => import('@/views/member/company/pages/list'),
      meta: {
        menuMember: true,
        text: '하부업체 리스트',
        done: true,
        code: "LV030100"
      }
    },
    {
      name: 'companyCreate',
      path: '/company/create',
      component: () => import('@/views/member/company/pages/companyCreate'),
      meta: {
        menuMember: true,
        text: '하부업체 생성',
        done: true,
        code: "LV030200"
      },
    },
    {
      path: '/company/detail',
      name: 'companyDetail',
      component: () => import("@/views/member/company/pages/detail"),
      meta: {
        menuMember: true,
        text: '하부업체 상세',
        done: true,
        code: "LV030300"
      }
    },
    {
      name: 'companyDetailView',
      path: '/company/DetailView',
      component: () => import('@/views/member/company/pages/detail'),
      meta: {
        layout: 'popup',
        menuMember: true,
        text: '하부업체 상세',
        done: true
      }
    },
    // {
    //   name: 'companyLoginList',
    //   path: '/company/loginList',
    //   component: () => import('@/views/member/company/pages/loginList'),
    //   meta: {
    //     menuMember: true,
    //     text: '하부업체 로그인 리스트'
    //   }
    // },
    // {
    //   name: "credit",
    //   path: "/company/credit",
    //   component: () => import("@/views/member/company/pages/credit"),
    //   redirect: {
    //     name: "companyCreditApply"
    //   },
    //   meta: {
    //     menuMember: true,
    //     text: "하부업체 PT관리",
    //     isActive: false
    //   },
    //   children: [
    //     {
    //       name: 'companyCreditApply',
    //       path: '/company/credit/apply',
    //       component: () => import('@/views/member/company/pages/credit/apply'),
    //       meta: {
    //         menuMember: true,
    //         text: 'PT 신청내역',
    //         done: true
    //       }
    //     },
    //     {
    //       name: 'creditExchange',
    //       path: '/company/credit/exchange',
    //       component: () => import('@/views/member/company/pages/credit/exchange'),
    //       meta: {
    //         menuMember: true,
    //         text: 'PT 환전내역',
    //         done: true
    //       }
    //     },
    //     {
    //       name: 'creditPointList',
    //       path: '/company/credit/pointList',
    //       component: () => import('@/views/member/company/pages/credit/pointList'),
    //       meta: {
    //         menuMember: true,
    //         text: '서브포인트 적립내역',
    //         done: true
    //       }
    //     }
    //   ]
    // },

    // {
    //   name: "ptCredit",
    //   path: "/pt/credit",
    //   component: () => import("@/views/member/pt/pages/credit"),
    //   redirect: {
    //     name: "companyCreditApply"
    //   },
    //   meta: {
    //     menuMember: true,
    //     text: "PT관리",
    //     isActive: false
    //   },
    //   children: [
    //     {
    //       name: 'ptCreditApply',
    //       path: '/pt/credit/apply',
    //       component: () => import('@/views/member/pt/pages/credit/apply'),
    //       meta: {
    //         menuMember: true,
    //         text: 'PT 신청내역',
    //         done: true
    //       }
    //     },
    //     {
    //       name: 'ptCreditExchange',
    //       path: '/pt/credit/exchange',
    //       component: () => import('@/views/member/pt/pages/credit/exchange'),
    //       meta: {
    //         menuMember: true,
    //         text: 'PT 환전내역',
    //         done: true
    //       }
    //     },
    //     {
    //       name: 'ptCreditPointList',
    //       path: '/pt/credit/pointList',
    //       component: () => import('@/views/member/pt/pages/credit/pointList'),
    //       meta: {
    //         menuMember: true,
    //         text: '서브포인트 적립내역',
    //         done: true
    //       }
    //     },
    //     {
    //       name: "subCredit",
    //       path: "/company/subCredit",
    //       component: () => import("@/views/member/company/pages/subCredit"),
    //       redirect: {
    //         name: "companyCreditApply"
    //       },
    //       meta: {
    //         menuMember: true,
    //         text: "하부 하부업체 PT관리",
    //         isActive: false
    //       },
    //       children: [
    //         {
    //           name: 'subCreditApply',
    //           path: '/company/subCredit/apply',
    //           component: () => import('@/views/member/company/pages/subCredit/apply'),
    //           meta: {
    //             menuMember: true,
    //             text: 'PT 신청내역',
    //             done: true
    //           }
    //         },
    //         {
    //           name: 'subCreditExchange',
    //           path: '/company/subCredit/exchange',
    //           component: () => import('@/views/member/company/pages/subCredit/exchange'),
    //           meta: {
    //             menuMember: true,
    //             text: 'PT 환전내역',
    //             done: true
    //           }
    //         },{
    //           name: 'subCreditPointList',
    //           path: '/company/subCredit/pointList',
    //           component: () => import('@/views/member/company/pages/subCredit/pointList'),
    //           meta: {
    //             menuMember: true,
    //             text: '서브포인트 적립내역',
    //             done: true
    //           }
    //         }
    //       ]
    //     },
    //   ]
    // },

    {
      path: '/company/bet/vendersetting/:id',
      name: 'venderRateSetting',
      component: () => import("@/views/member/company/pages/venderRateSetting"),
      meta: {
        menuMember: false,
        bodyClass: "popupBody"
      }
    },
    {
      path: '/company/bet/setting/:id',
      name: 'companyBetSetting',
      component: () => import("@/views/member/company/pages/rateSettings"),
      meta: {
        menuMember: false,
        bodyClass: "popupBody"
      }
    },
    {
      path: '/company/bet/bisetting/:id',
      name: 'biRateSettings',
      component: () => import("@/views/member/company/pages/biRateSettings"),
      meta: {
        menuMember: false,
        bodyClass: "popupBody"
      }
    },
    {
      path: '/company/bet/toggle/:id',
      name: 'rateSettingsToggle',
      component: () => import("@/views/member/company/pages/rateSettingsToggle"),
      meta: {
        menuMember: false,
        bodyClass: "popupBody"
      }
    },
  ]
}
