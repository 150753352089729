import store from '@/store'
import router from '@/router/index'
import axios from 'axios'
import { IS_PRODUCTION, HTTP_STATUS, HTTP_HEADER_SKIP_ERROR, DOCUMENT_TITLE_PREFIX } from '@/libs/constants'
import { removeAuthData, getAuthToken } from '@/libs/auth-helper'
import { decrypt, encrypt } from '@/libs/crypto'

const baseUrl = process.env.VUE_APP_API_BASE_URL

// 받아온 url의 첫번째 문자열을 확인
// '/'일 경우 true, 아닐경우 해당 url에 baseurl이 있는지 체크
function isApiUrl (url) {
  // console.log(url)
  if (url.charAt(0) === '/') {
    return true
  }
  return url.indexOf(baseUrl) === 0
}

const httpClient = axios.create({
  baseURL: baseUrl
})

httpClient.interceptors.request.use(
  function (config) {
    // console.log(config)
    if (isApiUrl(config.url)) {
      // url 검증이후 조건에 부합할 경우 하위 로직 실행, config 객체에 데이터 추가
      if (config.method === 'get') {
        if (!config.params) {
          config.params = {}
        }

        config.params.t = new Date().getTime()
      }

      config.data = encrypt(config.data)

      config.headers = Object.assign(config.headers, {
        // 기존 config.headers 객체에 새로운
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8',
        Accept: '*/*',
        Authorization: process.env.VUE_APP_API_KEY || 'ite1234567890abcdef'
      })

      const authToken = getAuthToken()
      if (config.url !== '/api/admin/login') {
        if (authToken) {
          config.headers.token = authToken
        } else {
          // console.log("notoken");
          const error = new Error('No token')
          return Promise.reject(error)
        }
      }
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

httpClient.interceptors.response.use(
  function (response) {
    response.data = decrypt(response.data)
    // console.log(response.config.url)
    // console.log(isApiUrl(response.config.url))
    // console.log(response.data.resultCode !== 0)
    if (!isApiUrl(response.config.url) && response.data.resultCode !== '0') {
      const message = response.data.message || 'RESPONSE_FAILED'
      const error = new Error(message)
      error.isFailed = true
      return Promise.reject(error)
    } else {
      // console.log(response)
      return response
    }
  },
  function (error) {
    // 에러 핸들링
    console.log(error)
    let needAlert = true

    if (error.config.headers[HTTP_HEADER_SKIP_ERROR]) {
      // 에러 스킵일 경우 알럿을 띄우지 않음
      needAlert = false
    } else if (error.response && error.response.status) {
      // 에러 response와 status가 둘다 존재할 하위 로직 진행
      const statusCode = error.response.status

      const authFailedStatusCodes = [HTTP_STATUS.unauthrized, HTTP_STATUS.unprocessableEntity]
      if (authFailedStatusCodes.includes(statusCode)) {
        // status가 상수에 등록된 status일 경우 하위 로직 진행
        needAlert = false
        if (window.__VUE_ROOT__) {
          window.__VUE_ROOT__.$children[0].signOut() // vue root 가 있으면 하위 루트의 signout함수 호출
        } else {
          removeAuthData() // 인증 데이터 삭제 auth-helper.js
        }
        router.push({ name: 'login' })
      } else if (statusCode === HTTP_STATUS.badRequest) {
        // removeAuthData()
        // router.push({ name: 'login' })
      }
    }

    if (needAlert) {
      error.isHandled = true
      store.commit('setErrorData', error) // 지정한 status 코드가 없을경우 store에 에러데이터 저장
    }
    return Promise.reject(error)
  }
)

export default httpClient
