<template>

    <template v-if="layoutName">
      <template v-if="layoutName === 'popup'">
        <popup-layout></popup-layout>
      </template>
      <template v-else>
        <default-layout />
      </template>
    </template>
    <template v-else>
      <router-view :key="$route.fullPath"/>
    </template>

</template>

<script>
import { getToken, headerCashInfo, headerTopInfo } from './api/member'
import { getAuthToken, removeAuthData, setAuthToken, setLocale, getSiteData, setCookieExtend } from '@/libs/auth-helper'

import DefaultLayout from './layouts/DefaultLayout.vue'

import { mapMutations, mapState } from 'vuex'
import Stomp from 'webstomp-client'
import SockJs from 'sockjs-client'

import { decrypt } from '@/libs/crypto'
import { SOCKET_SUBSCRIBE_DESTINATION, STORAGE_ITEM } from '@/libs/constants'
import store from '@/store'
import { parseJwt } from '@/libs/utils'
import PopupLayout from '@/layouts/PopupLayout.vue'

export default {
  name: 'App',
  components: {
    'default-layout': DefaultLayout,
    'popup-layout': PopupLayout
  },
  data () {
    return {
      hasError: false,
      lang: {},
      stompClient: null
    }
  },
  computed: {
    ...mapState([
      'siteOption'
    ]),
    layoutName () {
      if (!this.$route.matched.length) {
        return null
      }
      const route = this.$route
      // // console.log(route)
      const menuMember = route.meta.menuMember
      let useLayout = true
      if (!menuMember) {
        useLayout = false
      }

      console.log(route.meta.layout)

      if (route.meta.layout === 'popup') {
        return route.meta.layout
      }

      return useLayout
    }
  },
  watch: {
    $route: {
      async handler (current) {
        if (current.name !== null) {
          const authToken = getAuthToken()
          if (!authToken) {
            this.signOut()
          } else {
            await store.dispatch('storeSiteOption')
            this.socketConnect()
            await this.getCheckToken(false)
          }
        }
      },
      immediate: true
    }
  },
  async mounted () {
    window.CMS_TOKEN_CHECK = setInterval(async () => {
      await this.getCheckToken()
      const cookie = getAuthToken()
      if (cookie) {
        const res = await headerCashInfo()
        if (res.resultCode === '0') {
          await store.dispatch('storeAlarmInfo', res.data.headCashInfo)
        } else if (res.resultCode === 'TK999') {
          clearInterval(window.CMS_TOKEN_CHECK)
          this.signOut()
          alert('같은 아이디로 로그인되었습니다.')
        }
      }
    }, 1000 * 4)

    window.CMS_HEDER_TOP_INFO = setInterval(async () => {
      // await this.getCheckToken()
      const cookie = getAuthToken()
      if (cookie) {
        const res = await headerTopInfo()
        if (res.resultCode === '0') {
          await store.dispatch('storeHeaderTopInfo', res.data.headTopInfo)
        }
      }
    }, 1000 * 30)
  },
  methods: {
    loadSiteOption () {
      return store.dispatch('storeSiteOption')
    },
    async getCheckToken (force = false) {
      const cookie = getAuthToken()
      if (cookie) {
        const parseToken = parseJwt(cookie)
        const expireTime = Number(parseToken.exp + '000')
        const currentTime = new Date().getTime()
        const diffMinute = (expireTime - currentTime) / 1000 / 60

        if (force || diffMinute <= 5) {
          const res = await getToken()
          const resultCode = res.resultCode
          // const resultCode = "9999";
          if (resultCode !== '0') {
            // // console.log('kkkk')
            this.signOut()
          } else {
            setAuthToken(res.data)
            this.socketDisconnect()
            this.socketConnect()
          }
        }
      } else {
        this.signOut()
      }
    },
    socketDisconnect () {
      if (this.stompClient) {
        if (this.stompClient.client) {

        }
      }
      this.stompClient = null
    },
    socketConnect (type = 'adminfo', chatId = '1') {
      const token = getAuthToken()
      if (!token) {
        if (this.stompClient) {
          this.socketDisconnect()
        }
      }
      if (token && !this.stompClient) {
        this.stompClient = {
          client: null,
          token,
          type,
          chatId
        }
        const serverURL = process.env.VUE_APP_WEB_SOCKET_URL
        const socket = new SockJs(serverURL)
        this.stompClient.client = Stomp.over(socket, { debug: false })
        this.stompClient.client.connect(
          {},
          frame => {
            // 소켓 연결 성공
            // 서버의 메시지 전송 endpoint를 구독합니다.
            // 이런형태를 pub sub 구조라고 합니다.
            const currentToken = getAuthToken()
            setTimeout(() => {
              this.stompClient.client.subscribe(SOCKET_SUBSCRIBE_DESTINATION + type + '/' + currentToken, res => {
                this.getCheckToken()
                const jsonData = decrypt(res.body)
                const content = jsonData.content || null
                if (content) {
                  const msg = JSON.parse(jsonData.content)
                  store.dispatch('storeAlarmInfo', msg)
                }
              })
            }, 500)
          },
          error => {
            // 소켓 연결 실패
            console.error(error)
            this.socketDisconnect()
            this.socketConnect()
          }
        )
      }
    },
    ...mapMutations(['setErrorData', 'clearAllSiteData']),
    signOut () {
      this.clearAllSiteData()
      removeAuthData()
      this.$router.push({ name: 'login' })
    },
    async getMenuData () {
      await store.dispatch('storeMenuDate')
    }
  },
  async created () {
    await this.loadSiteOption()
    const token = getAuthToken()
    if (token) {
      await this.getMenuData()
      const res = await headerCashInfo()
      if (res.resultCode === '0') {
        await store.dispatch('storeAlarmInfo', res.data.headCashInfo)
      }

      const res2 = await headerTopInfo()
      if (res2.resultCode === '0') {
        await store.dispatch('storeHeaderTopInfo', res2.data.headTopInfo)
      }
      this.socketConnect()
    }
  },
  beforeRouteEnter (to, from, next) {
    const token = getAuthToken()
    const siteInfo = getSiteData()
    // console.log(token)
    if (token && siteInfo) {
      next()
    } else {
      removeAuthData()
      next('/login')
    }
  }
}
</script>

<style scoped>
/* .loginBody {
  background: #eee;
  height:100vh;
  min-width: 0;
} */
</style>
