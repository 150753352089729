export const cashBoth = {
  name: 'UserCashBoth',
  path: '/user/cash',
  component: () => import('@/views/member/cash'),
  redirect: {
    name: 'userInApply'
  },
  props: true,
  meta: {
    layout: 'default',
    isActive: false,
    text: '입출금 관리',
    icon: 'fas fa-money-bill-wave',
    code: 'LV070000'
  },
  children: [
    {
      name: 'userInApply',
      path: '/user/cash/in/apply',
      component: () => import('@/views/member/cash/pages/in/apply'),
      props: true,
      meta: {
        menuMember: true,
        text: '입금신청내역',
        code: 'LV070100'
      }
    },
    {
      name: 'depositReport',
      path: '/user/cash/in/report',
      component: () => import('@/views/member/cash/pages/in/report'),
      meta: {
        menuMember: true,
        text: '입금내역조회',
        code: 'LV070200'
      }
    },
    {
      name: 'userOutApply',
      path: '/user/cash/out/apply',
      props: true,
      component: () => import('@/views/member/cash/pages/out/apply'),
      meta: {
        menuMember: true,
        text: '출금신청내역',
        code: 'LV070300'
      }
    },
    {
      name: 'withdrawReport',
      path: '/user/cash/out/report',
      component: () => import('@/views/member/cash/pages/out/report'),
      meta: {
        menuMember: true,
        text: '출금내역조회',
        code: 'LV070400'
      }
    },
    {
      name: 'adminReport',
      path: '/user/cash/admin/list',
      component: () => import('@/views/member/cash/pages/admin/list'),
      meta: {
        menuMember: true,
        text: '관리자 지급/회수',
        code: 'LV070500'
      }
    },
    {
      name: 'cashVarianceHistory',
      path: '/user/cash/cashVarianceHistory',
      component: () => import('@/views/member/cash/pages/cashVarianceHistory'),
      meta: {
        menuMember: true,
        text: '캐시변동내역',
        code: 'LV070600'
      }
    }
  ]
}

export const cashIn = {
  name: 'CashIn',
  path: '/cash/in',
  component: () => import('@/views/member/cash'),
  redirect: {
    name: 'depositApply'
  },
  props: true,
  meta: {
    layout: 'default',
    isActive: false,
    text: '입금 관리',
    icon: 'fas fa-money-check'
  },
  children: [
    {
      name: 'depositApply',
      path: '/cash/in/apply',
      component: () => import('@/views/member/cash/pages/in/apply'),
      meta: {
        menuMember: true,
        text: '입금신청내역'
      }
    },
    {
      name: 'depositReport',
      path: '/cash/in/report',
      component: () => import('@/views/member/cash/pages/in/report'),
      meta: {
        menuMember: true,
        text: '입금내역조회'
      }
    }
  ]
}

export const cashOut = {
  name: 'CashOut',
  path: '/cash/out',
  component: () => import('@/views/member/cash'),
  redirect: {
    name: 'withdrawApply'
  },
  meta: {
    layout: 'default',
    isActive: false,
    text: '출금 관리',
    icon: 'fas fa-money-bill-alt'
  },
  children: [
    {
      name: 'withdrawApply',
      path: '/cash/out/apply',
      component: () => import('@/views/member/cash/pages/out/apply'),
      meta: {
        menuMember: true,
        text: '출금신청내역'
      }
    },
    {
      name: 'withdrawReport',
      path: '/cash/out/report',
      component: () => import('@/views/member/cash/pages/out/report'),
      meta: {
        menuMember: true,
        text: '출금내역조회'
      }
    }
  ]
}
