import { format, subDays } from 'date-fns'
import store from '@/store'

export function isPlainObject (value) {
  return Object.prototype.toString.call(value) === '[object Object]'
}

export function isValidUserId (value) {
  const regType = /^[A-Za-z0-9+]{3,20}$/
  return regType.test(value)
}

export function isValidPassword (value) {
  const regType = /^(?=.*[a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-])[a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]{4,}$/
  return !/\s/.test(value) && regType.test(value)
}

export function isInteger (value) {
  return typeof value === 'number' && isFinite(value) && Math.floor(value) === value
}
export function deleteCommas (value) {
  const split = value.split(',')
  let returnVal = ''
  for (const item of split) {
    returnVal += item
  }
  if (isNaN(returnVal)) {
    return null
  } else {
    return Number(returnVal)
  }
}
export function upperFirst (value) {
  value = String(value)

  if (!value.length) {
    return value
  }
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export function cloneDeep (data) {
  return JSON.parse(JSON.stringify(data))
}
export function jsonSort (item, ord, type) {
  let _type = 'asc'
  if (type) {
    _type = type
  }
  item.sort((a, b) => {
    if (_type === 'asc') {
      return Number(a[ord]) - Number(b[ord])
    } else if (_type === 'desc') {
      return Number(b[ord]) - Number(a[ord])
    }
  })
  return item
}

export function getPercentage (value, total, digits) {
  if (isNaN(value) || isNaN(total) || total === 0) {
    return 0
  }

  let result = (value / total) * 100

  if (digits !== undefined) {
    result = Number(result.toFixed(digits))
  }
  return result
}

export function getTimeString (value) {
  const hour = Math.floor(value / 3600)
  const minute = Math.floor(value / 60) % 60
  const second = Math.round(value % 60)
  if (hour >= 1) {
    return `${hour}h ${minute}m ${second}s`
  }
  if (minute >= 1) {
    return `${minute}m ${second}s`
  }
  return `${second}s`
}

export function getNumberWithOrdinal (n) {
  const s = ['th', 'st', 'nd', 'rd']
  const v = n % 100
  return n + (s[(v - 20) % 10] || s[v] || s[0])
}

export function thousand (value, fixed = 2) {
  // value = Number(value)
  // return value.toLocaleString('en-US', { minimumFractionDigits: fixed, maximumFractionDigits: fixed })
  const cashDecimal = (store && store.state && store.state.siteOption && store.state.siteOption.cashDecimal) ? store.state.siteOption.cashDecimal : 1
  const cash = typeof value === 'number' ? value * cashDecimal : Number(value) * cashDecimal
  if (cashDecimal === '1' || cashDecimal === 1) {
    fixed = 0
  }
  return cash.toLocaleString('en-US', { minimumFractionDigits: fixed, maximumFractionDigits: fixed })
}

export function thousandCount (value, fixed = 0) {
  value = typeof value === 'number' ? value : Number(value)
  return value.toLocaleString('en-US', { minimumFractionDigits: fixed, maximumFractionDigits: fixed })
}

export function leadingZero (number) {
  return number < 10 ? '0' + number : number
}

export function capitalize (string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function getDateStr (value, defaultStr) {
  if (!defaultStr) {
    defaultStr = 'yyyy-MM-dd HH:mm:ss'
  }

  return format(value, defaultStr)
}
export function replaceDateT (dateStr) {
  const _dateStr = dateStr.replace('T', ' ')
  return _dateStr
}

export function displayDateTime (dateStr, isUTC = false) {
  const _dateStr = isUTC ? dateStr + 'z' : dateStr.replace('T', ' ')
  const dateObject = new Date(_dateStr)

  const year = dateObject.getFullYear()
  const month = String(dateObject.getMonth() + 1).padStart(2, '0')
  const day = String(dateObject.getDate()).padStart(2, '0')
  const hour = String(dateObject.getHours()).padStart(2, '0')
  const minute = String(dateObject.getMinutes()).padStart(2, '0')

  const dateTimeString = `${year}-${month}-${day} ${hour}:${minute}`

  return dateTimeString
}

export function numberWithCommas (value) {
  return thousand(value)
}
export function getSubDaysDate (value, days) {
  return subDays(value, days)
}
export function onlyNumber (e) {
  const val = e.target.value
  e.target.value = val.replace(/[^0-9]/g, '')
  e.target.value = numberWithCommas(e.target.value)
}

export function timeConverter_seconds (seconds) {
  let timeType = ''
  if (seconds >= 60) {
    timeType = 'minutes'
  } else if (seconds >= 3600) {
    timeType = 'hours'
  } else {
    timeType = 'seconds'
  }

  let converTime = ''
  let HH = ''
  let MM = ''
  let SS = ''
  switch (timeType) {
    case 'seconds':
      converTime = ''
      break
    case 'minutes':
      MM = Math.floor(seconds / 60)
      SS = seconds - MM * 60

      converTime = `00:${MM}:${SS}`
      break
    case 'hours':
      HH = Math.floor(seconds / 3600)
      MM = Math.floor(seconds / 60)
      SS = seconds - MM * 60

      converTime = `${HH}:${MM}:${SS}`
      break
  }

  return converTime
}

let stopCount = 0
export function stopedInterval (item) {
  clearInterval(item)
  stopCount++
}

function b64DecodeUnicode (str) {
  return decodeURIComponent(
    Array.prototype.map.call(atob(str), c =>
      '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    ).join(''))
}

export function parseJwt (token) {
  return JSON.parse(
    b64DecodeUnicode(
      token.split('.')[1].replace('-', '+').replace('_', '/')
    )
  )
}
