export default {
  name: 'board',
  path: '/board',
  component: () => import('@/views/member/board'),
  redirect: {
    name: 'boardMessage'
  },
  meta: {
    layout: 'default',
    isActive: false,
    text: '고객센터 관리',
    icon: 'fas fa-question-circle',
    code: 'LV100000'
  },
  children: [
    {
      name: 'boardMessage',
      path: '/board/message',
      component: () => import('@/views/member/board/pages/message'),
      meta: {
        menuMember: true,
        text: '쪽지 관리',
        code: 'LV100100'
      }
    },
    {
      name: 'boardBoards',
      path: '/board/boards',
      component: () => import('@/views/member/board/pages/boards'),
      meta: {
        menuMember: true,
        text: ' 게시판 관리',
        code: 'LV100200'
      }
    },
    {
      name: 'boardQna',
      path: '/board/qna',
      component: () => import('@/views/member/board/pages/qna'),
      meta: {
        menuMember: true,
        text: '1:1 문의',
        code: 'LV100300'
      }
    },
    {
      name: 'boardNotice',
      path: '/board/notice',
      component: () => import('@/views/member/board/pages/notice'),
      meta: {
        menuMember: true,
        text: '공지사항 관리',
        code: 'LV100400'
      }
    },
    // {
    //   name: "boardCategory",
    //   path: "/board/category",
    //   component: () => import("@/views/member/board/pages/category"),
    //   meta: {
    //     menuMember: true,
    //     text: "카테고리 관리"
    //   }
    // },
    // {
    //   name: "boardCoupon",
    //   path: "/board/coupon",
    //   component: () => import("@/views/member/board/pages/coupon"),
    //   meta: {
    //     menuMember: true,
    //     text: "쿠폰 지급 관리"
    //   }
    // },
    {
      name: 'boardFaq',
      path: '/board/faq',
      component: () => import('@/views/member/board/pages/faq'),
      meta: {
        menuMember: true,
        text: '자주묻는질문 관리',
        code: 'LV100500'
      }
    },
    {
      name: 'BoardAnswerList',
      path: '/board/answerList',
      component: () => import('@/views/member/board/pages/answerList'),
      meta: {
        menuMember: true,
        text: '문의답변목록',
        code: 'LV100600'
      }
    }
    // {
    //   name: "BoardRealTimeCash",
    //   path: "/board/realTimeCash",
    //   component: () => import("@/views/member/board/pages/realTimeCash"),
    //   meta: {
    //     menuMember: true,
    //     text: "실시간 입출금 내역 추가"
    //   }
    // }
  ]
}
