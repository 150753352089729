import { createApp } from 'vue'
import router from './router/index'
import i18n from './i18n'
import store from './store'
import common from '@/plugins/common'
import App from './App.vue'
import { getAuthToken, getSiteData, removeAuthData, authBundle } from './libs/auth-helper'

import '@/assets/css/reset.css'
import '@/assets/css/style.css'
import '@/assets/css/style_pre.css'
import '@/assets/css/fontAwsome.css'

import mitt from 'mitt'
import VueApexCharts from 'vue3-apexcharts'
import ApexCharts from 'apexcharts'
import VueClipboard from 'vue-clipboard2'

const app = createApp(App)

const emitter = mitt()

app.config.globalProperties.emitter = emitter
app.config.globalProperties.$apexcharts = ApexCharts
app.config.autoSetContainer = true

// router.beforeEach((to, from, next) => {
//   console.log(to);
//   // next();
//   const guest = to.meta.guest;
//   console.log(guest);
//   if(guest){

//   }else{
//     const token = getAuthToken();
//     const site = getSiteData();
//     console.log(token);
//     console.log(!site)
//     console.log(!token)
//     if (!token || !site) {
//       console.log('removeData')
//       removeAuthData;
//       return {name:'login'}
//     }

//   }

//   // console.log(to)
//   // if (guest) {
//   //   to({name:'login'})
//   // } else {

//   // }
// });
app.use(store);
app.use(i18n);
app.use(router);
app.use(authBundle);

app.use(VueApexCharts)
app.use(VueClipboard)

app.mixin(common);

app.mount("body");
