import { createWebHistory, createRouter } from 'vue-router'
import { DOCUMENT_TITLE_PREFIX } from '@/libs/constants'
import { getSiteInfo, getAuthToken } from '@/libs/auth-helper'

// Member routers
import { dash } from './dash'
import user from './user'
import partner from './partner'
// import bet from "./bet";
import { bet } from './bet'
import company from './company'
import board from './board'
import reports from './reports'
import point from './point'
import settings from './settings'
import event from './event'
import admin from './admin'
import { cashBoth } from './cash'
import insure from './insure'
import pt from './pt'
import game from './game'
// const routeMembers = [user, casino,slot,minigame, company, partner, cashBoth, reports, board, point, settings];
const routeMembers = [dash, partner, user, bet, cashBoth, reports, game, company, pt, insure, board, settings, admin]

const routes = [
  {
    path: '/',
    name: 'main',
    component: () => import('@/views'),
    meta: {
      layout: 'default',
      guest: true
    },
    redirect: 'login'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login'),
    meta: {
      layout: 'login',
      guest: true,
      bodyClass: 'loginBody'
    }
  },
  {
    path: '/member',
    name: 'member',
    component: () => import('@/views/member'),
    meta: {
      guest: false
    },
    redirect: 'dash',
    children: routeMembers
  }
]
// console.log(routes);
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior () {
    return { top: 0, left: 0 }
  }
})

router.beforeEach((to, from, next) => {
  let title = DOCUMENT_TITLE_PREFIX
  const siteInfo = getSiteInfo()
  if (siteInfo) {
    title = siteInfo.siteId
  }
  document.title = title
  next()
})

export default router
