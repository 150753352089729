<template>
    <router-view :key="$route.fullPath"/>
</template>

<script>
export default {
  name: 'PopupLayout',
  components: {
  },
  data () {
    return {}
  },
  created () {
    console.log('popupCall')
  }
}
</script>

<style scoped></style>
