<template>
  <div class="sub-title">
    <h3>{{text}}</h3>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'PageSubTitle',
  props: ['text']
}
</script>

<style scoped>
.sub-title {
   color: #646464;
   font-size: 13px;
   padding-bottom: 11px;
   border-bottom: 2px solid #959595;
   display: flex;
   align-items: center;
   justify-content: space-between;
}
</style>
