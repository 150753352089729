export default {
  name: 'user',
  path: '/user',
  component: () => import('@/views/member/user'),
  redirect: {
    name: 'userList'
  },
  props: true,
  meta: {
    layout: 'default',
    isActive: false,
    text: '회원관리',
    icon: 'fas fa-user-cog',
    code: 'LV010000'
  },
  children: [
    {
      name: 'userCreate',
      path: '/user/Create',
      component: () => import('@/views/member/user/pages/userCreate'),
      meta: {
        menuMember: true,
        text: '회원 생성',
        done: true,
        code: 'LV010100'
      }
    },
    {
      name: 'userCreateView',
      path: '/user/CreateView',
      component: () => import('@/views/member/user/pages/userCreate'),
      meta: {
        layout: 'popup',
        menuMember: true,
        text: '회원 생성',
        done: true
      }
    },
    {
      name: 'userList',
      path: '/user/list',
      component: () => import('@/views/member/user/pages/list'),
      meta: {
        menuMember: true,
        text: '회원리스트',
        done: true,
        code: 'LV010200'
      }
    },
    {
      name: 'userList2',
      path: '/user/list2',
      component: () => import('@/views/member/user/pages/list2'),
      meta: {
        menuMember: true,
        text: '파트너별 회원리스트',
        done: true,
        code: 'LV010600'
      }
    },
    {
      name: 'userDetail',
      path: '/user/detail',
      component: () => import('@/views/member/user/pages/detail'),
      meta: {
        menuMember: true,
        text: '회원 관리',
        done: true,
        code: 'LV011200'
      }
    },
    {
      name: 'userDetailView',
      path: '/user/detailView',
      component: () => import('@/views/member/user/pages/detail'),
      meta: {
        layout: 'popup',
        menuMember: true,
        text: '회원 관리',
        done: true
      }
    },
    {
      name: 'userSignUpList',
      path: '/user/signUpList',
      props: true,
      component: () => import('@/views/member/user/pages/signUpList'),
      meta: {
        menuMember: true,
        text: '회원가입승인',
        done: true,
        code: 'LV010300'
      }
    },
    {
      name: 'userpayment',
      path: '/user/userpayment',
      component: () => import('@/views/member/user/pages/userpayment'),
      meta: {
        menuMember: true,
        text: '일괄 지급',
        done: true
      }
    },
    // {
    //   name: "userCallApply",
    //   path: "/user/userCallApply",
    //   component: () => import("@/views/member/user/pages/userCallList"),
    //   meta: {
    //     menuMember: true,
    //     text: "전화요청"
    //   }
    // },
    {
      name: 'UserCashReports',
      path: '/user/cash/report',
      component: () => import('@/views/member/user/pages/cashList'),
      meta: {
        menuMember: true,
        text: '회원 입출금'
        // code: 'LV010400'
      }
    },
    {
      name: 'UserCashChangeHistory',
      path: '/user/cash/change_history',
      component: () => import('@/views/member/user/pages/cashChangeHistory'),
      meta: {
        menuMember: true,
        text: '캐시이동내역',
        code: 'LV010500'
      }
    },
    // {
    //   name: "userRolling",
    //   path: "/user/rolling",
    //   component: () => import("@/views/member/user/pages/rolling"),
    //   meta: {
    //     menuMember: true,
    //     text: "롤링 관리"
    //   }
    // },
    {
      name: 'userPointList',
      path: '/user/pointList',
      component: () => import('@/views/member/user/pages/pointList'),
      meta: {
        menuMember: true,
        text: '포인트 관리',
        code: 'LV010700'
      }
    },
    {
      name: 'userTracking',
      path: '/user/userTracking',
      component: () => import('@/views/member/user/pages/userTracking'),
      meta: {
        menuMember: true,
        text: '사용자추적',
        code: 'LV010800'
      }
    },
    {
      name: 'userBlock',
      path: '/user/userBlock',
      component: () => import('@/views/member/user/pages/userBlock'),
      meta: {
        menuMember: true,
        text: 'IP차단 목록',
        code: ''
      }
    },
    {
      name: 'loginList',
      path: '/user/loginList',
      component: () => import('@/views/member/user/pages/loginList'),
      meta: {
        menuMember: true,
        text: '로그인한 회원',
        code: 'LV010900'
      }
    },
    {
      name: 'userGameRolling',
      path: '/user/userGameRate',
      component: () => import('@/views/member/user/pages/userGameRolling'),
      meta: {
        menuMember: true,
        text: '회원 롤링 요율관리',
        done: true,
        code: 'LV011000'
      }
    },
    {
      name: 'multiUserList',
      path: '/user/multiUsers',
      component: () => import('@/views/member/user/pages/multiUsers'),
      meta: {
        menuMember: true,
        text: '멀티아이피 내역',
        code: 'LV011100'
      }
    },
    // {
    //   name: "supUserChange",
    //   path: "/user/supUserChange",
    //   component: () => import("@/views/member/user/pages/supUserChange"),
    //   meta: {
    //     menuMember: true,
    //     text: "상위유저변경"
    //   }
    // },
    // {
    //   name: "userDetail",
    //   path: "/user/detail",
    //   component: () => import("@/views/member/user/pages/detail"),
    //   meta: {
    //     menuMember: false,
    //     bodyClass: "popupBody"
    //   }
    // },
    {
      path: '/user/cashHistory',
      name: 'CashHistory',
      component: () => import('@/views/member/user/pages/cashHistory'),
      meta: {
        menuMember: false
      }
    },
    {
      path: '/user/cash/cashMoveHistory',
      name: 'CashMoveHistory',
      component: () => import('@/views/member/user/pages/cashMoveHistory'),
      meta: {
        menuMember: true,
        text: '캐시 변동 내역'
      }
    },
    {
      path: '/user/detail/rate/setting/:id',
      name: 'rateSettings',
      component: () => import('@/views/member/company/pages/rateSettings'),
      meta: {
        menuMember: false,
        bodyClass: 'popupBody'
      }
    },
    {
      path: '/user/detail/bet/toggle/:id',
      name: 'rateSettingsToggle',
      component: () => import('@/views/member/company/pages/rateSettingsToggle'),
      meta: {
        menuMember: false,
        bodyClass: 'popupBody'
      }
    }
  ]
}
