import httpClient from '@/libs/http-client'

export function apiTest (data) {
  const url = '/test'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// top 15
export function top15ForHeader (data) {
  const url = '/api/admin/top15Info'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// month top 15
export function monthTop10ForHeader (data) {
  const url = '/api/admin/month10Info'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
