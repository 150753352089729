<template>
   <div class="navWrap">
      <nav :class="{ 'menu-open': isMenuOpen }">
        <button @click="toggleMenu" class="navBtn">
          <span></span>
        </button>
       <!--div class="header-lower">
         <div class="user-info-wrap">
           <div class="item fx">
             <div class="title">상위업체</div>
             <div class="contents">
               <div class="mId upper">{{ siteInfo.recommenderId }}</div>
             </div>
           </div>
         </div>
       </div-->
       <div class="nav-upper">
         <div class="user-info-wrap top ">
           <div class="tempBox-wrap first">
             <a href="/user/list" ><h1 class="using-site-info logo">{{siteInfo.siteName}}</h1></a>
             <div class="langWrap" @mouseover="showMenuLang = true" @mouseleave="showMenuLang = false" >
               <button class="langT">
                 <span class="" v-if="$i18n.locale === 'ko'"><img src="@/assets/img/korea.svg" /><span class="langbox">KR</span></span>
                 <span class="" v-if="$i18n.locale === 'en'"><img src="@/assets/img/en.svg" /><span class="langbox">EN</span></span>
               </button>
               <div class="langB" v-if="showMenuLang">
                   <a href="#" :class="{'on': $i18n.locale === 'ko'}" @click="changeLocale('ko')"><img src="@/assets/img/korea.svg" /><span>KR</span></a>
                   <a href="#" :class="{'on': $i18n.locale === 'en'}" @click="changeLocale('en')"><img src="@/assets/img/en.svg" /><span>EN</span></a>
               </div>
             </div>
           </div>
         </div>
       </div>
       <div class="nav-upper">
         <div class="user-info-wrap top">
           <h2>MAIN INFO <em class="inspect" :class="{'on': alarmInfo.siteCheckYn === 'Y', 'off': alarmInfo.siteCheckYn === 'N'}"></em></h2>
           <div class="contents">
             <div class="mId">{{ $t("common.admin") }}</div>
             <div class="logout-name">
               {{ siteInfo.adminId }}
               <button type="button" @click="signOut" class="logout-link"><img src="@/assets/img/icon_logout.svg" /></button>
             </div>
           </div>
         </div>
         <div class="user-info-wrap credit-wrap" v-if="isPT">
           <div class="tempBox-wrap pt">
             <div class="tempBox-item">
               <div class="tempBox">
                 <div class="title">{{ $t("common.credit") }}</div>
                 <div class="content-val">{{ numberWithCommas(alarmInfo.creditAmt) }}</div>
               </div>
               <div class="tempBox point">
                 <div class="title">{{ $t("common.point") }}</div>
                 <div class="content-val">{{ numberWithCommas(alarmInfo.creditSubPoint) }}</div>
               </div>
             </div>
             <div class="btn-credit-wrap fx gap-5 fx-col-sbw">
   <!--            <button class="btn-credit-apply" type="button" @click="modalOpen('apply')">{{ $t("notification.ptApply") }}</button>-->
               <button class="btn-credit-apply" type="button" @click="openLayer('openPTApply')">{{ $t("notification.ptApply") }}</button>

               <button class="btn-credit-apply" type="button" @click="openLayer('openSubPointApply')">
                 {{ $t("notification.subPT") }}
               </button>
             </div>
           </div>
           <template v-if="isInsured && siteInfo.partnerType !== 'senior'">
             <div class="tempBox-wrap bi">
               <div class="tempBox-item">
                 <div class="tempBox">
                   <div class="title">{{ $t("common.insurance") }}</div>
                   <div class="content-val">{{ numberWithCommas(alarmInfo.insuredAmt) }}</div>
                 </div>
                 <div class="tempBox point">
                   <div class="title">{{ $t("common.point") }}</div>
                   <div class="content-val">{{ numberWithCommas(alarmInfo.insuredSubPoint) }}</div>
                 </div>
               </div>
               <div class="btn-credit-wrap fx gap-5 fx-col-sbw">
                 <button class="btn-credit-apply" type="button" @click="modalOpen2('apply')">{{ $t("common.bibet") }} {{ $t("common.request") }}</button>
                 <button class="btn-credit-apply" type="button" @click="modalOpen2('exchange')">{{ $t("common.bibet") }} {{ $t("common.exchange") }}</button>
               </div>
             </div>
           </template>
          <div class="tempBox-wrap bi hd" v-if="isHoldem">
            <div class="tempBox-item">
              <div class="tempBox">
                <div class="title">{{ $t("company.holdPt") }}</div>
                <div class="content-val">{{ numberWithCommas(alarmInfo.creditAmtHoldem)}}</div>
              </div>
            </div>
          </div>
         </div>
       </div>
       <!--article class="notification-wrap">
         <h2>CURRENT STATE</h2>
         <div class="noti-item">
           <h4>{{ $t("common.deposit") }}</h4>
           <router-link :to="{ name: 'userInApply', query: { status: 'all', time: new Date().getTime() } }" class="contents-box">
             <span class="text">{{ $t("common.request") }}</span>
             <span>
               (<span :class="{ on: alarmInfo.cashInReqCnt !== 0 }">{{ alarmInfo.cashInReqCnt }}</span
               >)
             </span>
           </router-link>
           <router-link :to="{ name: 'userInApply', query: { status: '2', time: new Date().getTime() } }" class="contents-box">
             <span class="text">{{ $t("common.wait") }}</span>
             <span>
               (<span :class="{ on: alarmInfo.cashInWaitCnt !== 0 }">{{ alarmInfo.cashInWaitCnt }}</span
               >)
             </span>
           </router-link>
         </div>

         <div class="noti-item">
           <h4>{{ $t("common.withdraw") }}</h4>
           <router-link :to="{ name: 'userOutApply', query: { status: 'all', time: new Date().getTime() } }" class="contents-box">
             <span class="text">{{ $t("common.request") }}</span>
             <span>
               (<span :class="{ on: alarmInfo.cashOutReqCnt !== 0 }">{{ alarmInfo.cashOutReqCnt }}</span
               >)
             </span>
           </router-link>
           <router-link :to="{ name: 'userOutApply', query: { status: '2', time: new Date().getTime() } }" class="contents-box">
             <span class="text">{{ $t("common.wait") }}</span>
             <span>
               (<span :class="{ on: alarmInfo.cashOutWaitCnt !== 0 }">{{ alarmInfo.cashOutWaitCnt }}</span
               >)
             </span>
           </router-link>
         </div>
         <div class="noti-item">
           <h4>{{ $t("common.register") }}</h4>
           <router-link :to="{ name: 'userSignUpList', query: { status: 'all', time: new Date().getTime() } }" class="contents-box title">
             <span class="text">{{ $t("common.request") }}</span>
             <span>
               (<span :class="{ on: alarmInfo.memJoinReqCnt !== 0 }">{{ alarmInfo.memJoinReqCnt }}</span
               >)
             </span>
           </router-link>
           <router-link :to="{ name: 'userSignUpList', query: { status: '2', time: new Date().getTime() } }" class="contents-box">
             <span class="text">{{ $t("common.wait") }}</span>
             <span>
               (<span :class="{ on: alarmInfo.memJoinWaitCnt !== 0 }">{{ alarmInfo.memJoinWaitCnt }}</span
               >)
             </span>
           </router-link>
         </div>
         <-- todo ->
         <div class="noti-item">
           <h4>{{ $t("common.qna2") }}</h4>
           <router-link :to="{ name: 'boardQna', query: { time: new Date().getTime() } }" class="contents-box">
             <span class="text">{{ $t("common.request") }}</span>
             <span>
               (<span :class="{ on: alarmInfo.qnaReqCnt !== 0 }">{{ alarmInfo.qnaReqCnt }}</span
               >)
             </span>
           </router-link>
           <router-link :to="{ name: 'boardQna', query: { time: new Date().getTime() } }" class="contents-box">
             <span class="text">{{ $t("common.wait") }}</span>
             <span>
               (<span :class="{ on: alarmInfo.qnaWaitCnt !== 0 }">{{ alarmInfo.qnaWaitCnt }}</span
               >)
             </span>
           </router-link>
         </div>
         <div class="noti-item" v-if="siteInfo.partnerType !== 'agency'">
           <h4>{{ $t("common.credit") }}</h4>
           <router-link :to="{ name: 'subCreditApply', query: { status: '0', time: new Date().getTime() } }" class="contents-box">
             <span class="text">{{ $t("common.request") }}</span>
             <span>
               (<span :class="{ on: alarmInfo.creditCnt !== 0 }">{{ alarmInfo.creditCnt }}</span
               >)
             </span>
           </router-link>
           <router-link :to="{ name: 'subCreditExchange', query: { status: '0', time: new Date().getTime() } }" class="contents-box">
             <span class="text">{{ $t("common.exchange") }}</span>
             <span>
               (<span :class="{ on: alarmInfo.creditExchangeCnt !== 0 }">{{ alarmInfo.creditExchangeCnt }}</span
               >)
             </span>
           </router-link>
         </div>
         <div class="noti-item" v-if="siteInfo.partnerType !== 'agency'">
           <h4>{{ $t("common.bibet") }}</h4>
           <router-link :to="{ name: 'insuredApplyList', query: { status: '0', time: new Date().getTime() } }" class="contents-box">
             <span class="text">{{ $t("common.request") }}</span>
             <span>
               (<span :class="{ on: alarmInfo.insuranceCnt !== 0 }">{{ alarmInfo.insuranceCnt }}</span
               >)
             </span>
           </router-link>
           <router-link :to="{ name: 'insuredExchangeList', query: { status: '0', time: new Date().getTime() } }" class="contents-box">
             <span class="text">{{ $t("common.exchange") }}</span>
             <span>
               (<span :class="{ on: alarmInfo.insuranceExchangeCnt !== 0 }">{{ alarmInfo.insuranceExchangeCnt }}</span
               >)
             </span>
           </router-link>
         </div>
         <-- <div class="noti-item">
           <router-link :to="{ name: 'companyCreditApply', params: { status: '2' } }" class="contents-box">
             <span class="text">{{ $t("notification.notis.insuredSummary") }}</span>
             <span>
               (<span :class="{ on: headCashInfo.biSummaryApplyCnt !== 0 }">{{ headCashInfo.biSummaryApplyCnt }}</span>)
             </span>
           </router-link>
         </div> ->
       </article-->
       <ul class="menu-wrap nav-bottom" v-if="isLoaded">
          <li>
            <router-link :to="{ name: 'dashboard'}">
              <ul class="content-wrap" :class="{ active: isActive('/dash') }">
                <li class="menu-title">
                  <div class="text-wrap">
                    <i class="fa fa-th-large"></i>
                    <span class="text">{{$t('common.dash')}}</span>
                  </div>
                </li>
              </ul>
            </router-link>
          </li>
         <template v-for="menu in menuList" :key="menu.name">
           <li v-if="menu.exceptType !== siteInfo.partnerType && menu.text !== '대시보드'">
             <ul class="content-wrap" :class="{ active: menu.isActive }">
               <li class="menu-title" @click="menu.isActive = !menu.isActive">
                 <div class="text-wrap">
                   <i :class="menu.icon" />
                   <span class="text">{{ menu.text }}</span>
                 </div>
                 <i class="fas fa-chevron-right" />
               </li>
               <li class="menu-contents">
                 <ul>
                   <template v-for="child in menu.children">
                     <li :key="child.path" v-if="child.meta.menuMember && child.meta.exceptType !== siteInfo.partnerType">
                       <a v-if="!child.children" class="supMenu" :class="{ active: child.meta.isActive }" @click="goToPageName(child.path)">
                         <span v-if="child.meta.text">{{ child.meta.text }}</span>
                       </a>
                       <ul class="subMenu-container" v-else>
                         <li class="supMenu subMenu-title" @click="child.meta.isActive = !child.meta.isActive" :class="[{ active: child.meta.isActive }]">{{ child.meta.text }}<i class="fas fa-chevron-right"></i></li>
                         <li>
                           <ul class="subMenu-wrap" :class="{ active: child.meta.isActive }">
                             <template v-for="subMenu in child.children" :key="subMenu.path">
                               <li v-if="subMenu.meta.hasOwnProperty('exceptType') ? (subMenu.meta.exceptType === siteInfo.partnerType ? false : true) : true">
                                 <a @click="goToPageName(subMenu.path)" class="subMenu" :class="{ active: subMenu.meta.isActive }">{{ subMenu.meta.text }}</a>
                               </li>
                             </template>
                           </ul>
                         </li>
                       </ul>
                     </li>
                   </template>
                 </ul>
               </li>
             </ul>
           </li>
         </template>
       </ul>
     </nav>
     <notification-alarm :headInfo="headCashInfo" />
  </div>

  <div class="modalWrap" v-if="insuredModal.active">
    <div class="modal-item modal2">
      <article class="modal-title">
        <!--sub-title :text="$t('cash.appIns')" v-if="insuredModal.type === 'apply'" />
        <sub-title :text="$t('cash.exchIns')" v-if="insuredModal.type === 'exchange'" /-->
        <div>
          <ul class="PTtabmenu">
            <li v-if="insuredModal.type === 'apply'"><h3>{{ $t('cash.appIns') }}</h3></li>
            <li v-if="insuredModal.type === 'exchange'"><h3 class="fc-gn">{{ $t('cash.exchIns') }}</h3></li>
          </ul>
        </div>
        <button class="btn-close" type="button" @click="modalClose2"><i class="fa fa-times" aria-hidden="true"></i></button>
      </article>
      <div class="modal-body">
        <article class="fx-item-auto fx gap-5">
          <div class="item-title">{{ $t('cash.holdingIs') }}</div>
          <input type="text" :value="numberWithCommas(insuredModal.insuredAmt)" disabled />
        </article>
        <article class="fx-item-auto fx gap-5" v-if="insuredModal.type === 'apply'">
          <div class="item-title bg-sb">{{ $t('notification.holdingSub') }}</div>
          <input v-if="!useBiSubPointYn" type="text" :value="numberWithCommas(insuredModal.insuredSubPoint)" disabled />
          <input v-else type="text" :value="0" disabled />
        </article>
        <article class="fx-item-auto fx gap-5">
          <div class="item-title" v-if="insuredModal.type === 'apply'">{{ $t('cash.applyIs') }}</div>
          <div class="item-title bg-gb" v-if="insuredModal.type === 'exchange'">{{ $t('cash.exchIsAmt') }}</div>
          <input type="text" ref="biApplyInput" maxlength="15" :value="numberWithCommas(biApplyData.insuredAmt)" @input="setItem($event, biApplyData, 'insuredAmt')" />
        </article>
        <article class="fx-item-auto fx gap-5" v-if="insuredModal.type === 'apply'">
          <div class="item-title bg-gn">{{ $t('cash.subPointYN') }}</div>
          <ui-check :id="`useSubPoint`" :checked="useBiSubPointYn" @change="setUseBiSubPointYn(useBiSubPointYn)" />
          <span class="modalNotice" v-if="useBiSubPointYn">{{ $t('cash.useSubPT') }} : {{ numberWithCommas(insuredModal.insuredSubPoint) }}</span>
        </article>
      </div>
      <div class="btn-wrap">
        <button class="btn-layout" type="button" @click="applyInsured" v-if="insuredModal.type === 'apply'">{{ $t('button.payApply') }}</button>
        <button class="btn-layout h-bg-gb" type="button" @click="exchangeInsured" v-if="insuredModal.type === 'exchange'">{{ $t('button.exchApply') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import lodash from 'lodash'
import { removeAuthData, getSiteData, setLangCookie } from '@/libs/auth-helper'
import { companyDetail, creditChargeApply, creditChargeApplySubPoint, creditWithdrawApply, headerCashInfo } from '@/api/member'
import { insuredApply } from '@/api/insured'
import { mapGetters, mapMutations, mapState } from 'vuex'
import subTitle from '@/components/main/PageSubTitle'
import UiCheck from '@/components/ui/UiCheck'
import NotificationAlarm from '@/components/main/header/NotificationAlarm'
import store from '@/store'
import { numberWithCommas } from '@/libs/utils'

export default {
  name: 'SiteMenu',
  components: {
    subTitle,
    UiCheck,
    NotificationAlarm
  },
  data () {
    return {
      isMenuOpen: false,
      useBiSubPointYn: false,
      currentTab: 'PTtab-1',
      items: [],
      loginPath: '/login',
      siteInfo: null,
      menuList: [],
      currentMenu: '',
      creditApplyData: {
        creditType: '2',
        creditAmt: '0'
      },
      biApplyData: {
        siteId: '',
        insuredType: '1',
        insuredAmt: '0',
        insuredStatus: '0'
      },
      cashInfo: {},
      currentSubMenu: '',
      creditAmt: 0,
      creditSubPoint: 0,
      biAmt: 0,
      insuredSubPoint: 0,
      useSubPointYn: false,
      modalType: '',
      modalActive: false,
      creditApplyAmt: 0,
      modalData: {
        creditAmt: 0,
        creditSubPoint: 0
      },
      insuredModal: {
        active: false,
        type: '',
        insuredAmt: 100,
        insuredSubPoint: 0
      },
      preCreditSubPoint: 0,
      LV000001: false,
      LV000002: false,
      isLoaded: false,
      showMenuLang: false
    }
  },
  methods: {
    isActive (url) {
      return this.$route.path === url
    },
    changeLocale (lang) {
      if (this.$i18n.locale === lang) {
        return
      } else {
        this.$i18n.locale = lang
      }
      setLangCookie(lang)
      this.showMenuLang = false
      location.reload()
    },
    toggleMenu () {
      this.isMenuOpen = !this.isMenuOpen
    },
    openLayer (type) {
      console.log(type)
      this.emitter.emit(type, true)
    },
    numberWithCommas,
    goToPageName (value) {
      this.$router.push(value)
    },
    setItem (e, item, key) {
      this.onlyNumber(e)
      if (this.insuredModal.active) {
        if (this.insuredModal.type === 'exchange') {
          if (this.deleteCommas(e.target.value) > Number(this.insuredModal.insuredAmt)) {
            e.target.value = this.numberWithCommas(lodash.cloneDeep(this.insuredModal.insuredAmt))
          }
        }
      } else if (this.modalActive) {
        if (this.modalType === 'exchange') {
          if (this.deleteCommas(e.target.value) > Number(this.modalData.creditAmt)) {
            e.target.value = this.numberWithCommas(lodash.cloneDeep(this.modalData.creditAmt))
          }
        }
      }
      if (key) {
        item[key] = this.deleteCommas(e.target.value)
      } else {
        this[item] = this.deleteCommas(e.target.value)
      }
    },
    setUseSubPointYn (item) {
      this.useSubPointYn = !item
    },
    setUseBiSubPointYn (item) {
      this.useBiSubPointYn = !item
    },
    modalOpen (type) {
      this.modalType = type
      this.modalData.creditAmt = lodash.cloneDeep(this.alarmInfo.creditAmt)
      this.modalData.creditSubPoint = lodash.cloneDeep(this.alarmInfo.creditSubPoint)
      this.modalActive = true
    },
    modalOpen2 (type) {
      this.insuredModal.insuredAmt = lodash.cloneDeep(this.alarmInfo.insuredAmt)
      this.insuredModal.insuredSubPoint = lodash.cloneDeep(this.alarmInfo.insuredSubPoint)
      this.insuredModal.type = type
      this.insuredModal.active = true
    },
    modalClose () {
      this.creditApplyAmt = 0
      this.modalActive = false
    },
    modalClose2 () {
      this.insuredModal.type = ''
      this.insuredModal.active = false
      this.creditApplyAmt = 0
    },
    async creditApply () {
      const creditAmt = this.creditApplyAmt
      if (this.currentTab === 'PTtab-1') {
        if (creditAmt === null || creditAmt === undefined) {
          return
        } else if (creditAmt <= 0) {
          alert('최소 충전 PT은 0 이상이어야 합니다.')
          return
        } else if (isNaN(creditAmt)) {
          alert('숫자만 작성해주세요.')
          return
        }
      }

      const param = {}

      if (this.currentTab === 'PTtab-1') {
        param.creditAmt = creditAmt
      }

      if (this.currentTab === 'PTtab-2') {
        param.creditType = this.creditApplyData.creditType
      }

      console.log(param)

      const trigger = confirm(`${creditAmt}PT을 요청하시겠습니까?`)
      if (trigger) {
        let result
        if (this.currentTab === 'PTtab-1') {
          result = await creditChargeApply(param)
        } else {
          result = await creditChargeApplySubPoint(param)
        }

        if (result.resultCode === '0') {
          alert('알 신청완료')
          this.modalClose()
        } else {
          alert('알 요청 실패, 다시 시도해주세요.')
        }
      }
      this.creditApplyData.creditAmt = 0
    },
    async creditExchange () {
      const creditAmt = this.creditApplyAmt
      // console.log(creditAmt);
      if (creditAmt === null || creditAmt === undefined) {
        return
      } else if (creditAmt <= 0) {
        alert('최소 환전 PT은 0 이상이어야 합니다.')
        return
      } else if (isNaN(creditAmt)) {
        alert('숫자만 작성해주세요.')
        return
      }
      this.creditApplyData.creditType = '-1'
      this.creditApplyData.creditAmt = creditAmt
      const trigger = confirm(`${creditAmt}PT를 환전합니다.`)
      if (trigger) {
        // eslint-disable-next-line no-undef
        const result = await creditWithdrawApply(this.creditApplyData)
        // console.log(result);
        if (result.resultCode === '0') {
          alert('알 환전신청완료')
          this.modalClose()
        } else {
          alert('알 환전 요청 실패, 다시 시도해주세요.')
        }
      }
      this.creditApplyData.creditAmt = 0
    },
    async applyInsured () {
      const biAmt = this.biApplyData
      biAmt.siteId = this.siteInfo.siteId
      if (this.useBiSubPointYn) {
        biAmt.insuredType = '2'
      } else {
        biAmt.insuredType = '1'
      }
      if (biAmt.insuredAmt === null || biAmt.insuredAmt === undefined) {
        return
      } else if (biAmt.insuredAmt <= 0) {
        alert('최소 지급신청 보험금은 0 이상이어야 합니다.')
        return
      } else if (isNaN(biAmt.insuredAmt)) {
        alert('숫자만 작성해주세요.')
        return
      }
      const trigger = confirm(`${this.numberWithCommas(biAmt.insuredAmt)}보험금을 충전합니다.`)
      if (trigger) {
        const result = await insuredApply(biAmt)
        // console.log(result);
        if (result.resultCode === '0') {
          alert('보험금 지급 신청완료')
          this.modalClose2()
        } else {
          alert('보험금 지급 요청 실패, 다시 시도해주세요.')
        }
      }
      this.biApplyData.insuredAmt = 0
    },
    async exchangeInsured () {
      const biAmt = this.biApplyData
      biAmt.siteId = this.siteInfo.siteId
      biAmt.insuredType = '-1'
      if (biAmt.insuredAmt === null || biAmt.insuredAmt === undefined) {
        return
      } else if (biAmt.insuredAmt <= 0) {
        alert('최소 환전신청 보험금은 0 이상이어야 합니다.')
        return
      } else if (isNaN(biAmt.insuredAmt)) {
        alert('숫자만 작성해주세요.')
        return
      }
      const trigger = confirm(`${this.numberWithCommas(biAmt.insuredAmt)}보험금을 환전합니다.`)
      if (trigger) {
        biAmt.insuredAmt = biAmt.insuredAmt / Number(this.siteOption.cashDecimal)
        const result = await insuredApply(biAmt)
        // console.log(result);
        if (result.resultCode === '0') {
          alert('보험금 환전 신청완료')
          this.modalClose2()
        } else {
          alert('보험금 환전 요청 실패, 다시 시도해주세요.')
        }
      }
      this.biApplyData.insuredAmt = 0
    },
    getSiteName () {
      const siteInfoCookie = getSiteData()
      if (siteInfoCookie) {
        return JSON.parse(siteInfoCookie)
      }
    },
    async getUpperCompany () {
      const siteId = this.siteInfo.siteId
      const reqDetail = {
        siteId,
        memId: siteId
      }
      console.log('getUpperCompany : ', this.siteInfo)
      if (!this.siteInfo) {
        const loginCompanyDetail = await companyDetail(reqDetail)
        // console.log(loginCompanyDetail);
        const member = loginCompanyDetail.data.memberDetail
        if (!member.partnerType) {
          this.siteInfo.partnerType = member.partnerType
        } else {
          this.siteInfo.partnerType = member.partnerType
        }
        if (!member.recommenderId) {
          this.siteInfo.recommenderId = 'siteOperator'
        } else {
          this.siteInfo.recommenderId = member.recommenderId
        }
        if (!member.memName) {
          this.siteInfo.siteName = member.memId
        } else {
          this.siteInfo.siteName = member.memName
        }
        this.siteInfo.creditRate = member.creditRate
      }
    },
    ...mapMutations(['setErrorData', 'clearAllSiteData']),
    signOut () {
      this.clearAllSiteData()
      removeAuthData()

      location.href = '/login'
    },
    async setMenuList () {
      this.isLoaded = false

      const locale = this.$i18n.locale

      setTimeout(() => {
        this.menuList = []
        const menuObj = this.menuData

        if (menuObj) {
          const items = this.$router.getRoutes()

          const partnerType = this.siteInfo.partnerType
          for (const item of items) {
            const routeName = item.name

            if (routeName === 'member') {
              for (const mItem of item.children) {
                const code = mItem.meta.code

                if (code && menuObj[code]) {
                  const model = {
                    name: mItem.name,
                    path: mItem.path,
                    text: locale === 'ko' ? menuObj[code].menuCodeName : menuObj[code]['menuCodeName' + locale.toUpperCase()],
                    icon: mItem.meta.icon,
                    isActive: mItem.meta.isActive,
                    exceptType: mItem.meta.exceptType || false
                  }
                  mItem.meta.text = model.text
                  const children = mItem.children
                  const realChildren = []
                  for (const cItem of children) {
                    const cCode = cItem.meta.code
                    if (cCode && menuObj[cCode]) {
                      cItem.meta.text = locale === 'ko' ? menuObj[cCode].menuCodeName : menuObj[cCode]['menuCodeName' + locale.toUpperCase()]

                      const subChildren = cItem.children
                      const subList = []
                      if (subChildren) {
                        for (const subItem of subChildren) {
                          const subCode = subItem.meta.code
                          if (subCode && menuObj[subCode]) {
                            subItem.meta.text = locale === 'ko' ? menuObj[subCode].menuCodeName : menuObj[subCode]['menuCodeName' + locale.toUpperCase()]
                            subList.push(subItem)
                          }
                        }
                        cItem.children = subList
                      }

                      if (cItem.meta.code === 'LV110300') {
                        if (this.siteInfo.siteId === this.siteInfo.adminId) {
                          realChildren.push(cItem)
                        }
                      } else {
                        realChildren.push(cItem)
                      }
                    }
                  }

                  model.children = realChildren

                  this.menuList.push(model)
                }
              }
            }
          }
          this.getCurrentMenu()
        }
      }, 0)
    },
    getCurrentMenu () {
      this.currentMenu = this.$route.matched[1]
      this.currentSubMenu = this.$route.matched[2]
      this.currentGrandSubMenu = this.$route.matched[3]
      for (const menu of this.menuList) {
        if (menu.name === this.currentMenu.name) {
          menu.isActive = true
          for (const subMenu of menu.children) {
            if (subMenu.name === this.currentSubMenu.name) {
              this.$route.meta.text = subMenu.meta.text
              subMenu.meta.isActive = true
            } else {
              subMenu.meta.isActive = false
            }
            if (this.currentGrandSubMenu) {
              if (subMenu.children) {
                for (const grandChild of subMenu.children) {
                  if (grandChild.name === this.currentGrandSubMenu.name) {
                    if (grandChild.name.indexOf('BetList') > -1) {
                      const gameId_router = this.$route.params.id
                      const menuPath = grandChild.path.split('/')
                      const gameId_menu = menuPath[4]

                      if (gameId_router === gameId_menu) {
                        grandChild.meta.isActive = true
                      } else {
                        grandChild.meta.isActive = false
                      }
                    } else {
                      grandChild.meta.isActive = true
                    }
                  } else {
                    grandChild.meta.isActive = false
                  }
                }
              }
            }
          }
        } else {
          menu.isActive = false
        }
      }
      this.isLoaded = true
    }
  },
  watch: {
    $i18n () {
      this.setMenuList()
    },
    $route: {
      handler () {
        // console.log('pageTitle : ', this.$route)
        if (this.$route.fullPath !== '/login') {
          this.setMenuList()
          this.getCurrentMenu()
        }
      }
    },
    gameList: {
      async handler (value) {
        await this.getUpperCompany()
        await this.setMenuList()
      }
    },
    useSubPointYn: {
      handler (checked) {
        if (checked) {
          if (Number(this.siteInfo.creditRate) !== 0) {
            this.creditApplyAmt = Math.ceil((Number(this.modalData.creditSubPoint) / Number(this.siteInfo.creditRate)) * 100)
          } else {
            this.creditApplyAmt = 0
          }
          this.preCreditSubPoint = lodash.cloneDeep(this.modalData.creditSubPoint)
          this.modalData.creditPointAmt = 0
          this.$refs.creditApplyInput.setAttribute('disabled', true)
        } else {
          this.modalData.creditPointAmt = lodash.cloneDeep(this.creditPointAmt)
          this.creditApplyAmt = 0

          this.$refs.creditApplyInput.removeAttribute('disabled')
        }
      }
    },
    menuData (state) {
      this.setMenuList()
    },
    useBiSubPointYn (yn) {
      if (yn) {
        this.$refs.biApplyInput.setAttribute('readonly', true)
        this.creditApplyAmt = lodash.cloneDeep(this.alarmInfo.insuredSubPoint)
        this.biApplyData.insuredAmt = this.creditApplyAmt
      } else {
        this.$refs.biApplyInput.removeAttribute('readonly')
        this.creditApplyAmt = 0
        this.biApplyData.insuredAmt = 0
      }
    }
  },
  computed: {
    ...mapGetters(['gameList', 'headCashInfo']),
    ...mapState(['menuData', 'isPT', 'isInsured', 'isHoldem', 'alarmInfo', 'siteOption'])
  },
  async created () {
    this.siteInfo = this.getSiteName()
    const res = await headerCashInfo()
    if (res.resultCode === '0') {
      store.commit('setAlarmInfo', res.data.headCashInfo)
    }
    await this.getUpperCompany()
    await this.setMenuList()
    await this.getCurrentMenu()
  }
}
</script>

<style scoped>
nav {
  width: 250px;
  min-height: calc(100vh + 73px);
  padding-bottom: 40px;
  background-image: linear-gradient(167deg, #24496e, #020406 100%);
  position: relative;
  transition: margin-left 0.5s;
  z-index: 11111;}
/*nav::before {content: '';
    background-image: linear-gradient(167deg, #24496e, #020406 100%);
    position: fixed;
    width: 250px;
    height: calc(100vh + 75px);
    z-index: -1;}*/
.navBtn {
  position: absolute;
  width: 18px;
  height: 99px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url(../../assets/img/menubutton.svg) no-repeat;
  background-size: cover;
  right: -18px;
  top: 450px;
  z-index: 100;
  cursor: pointer;
}
.navBtn span {
  background: url(../../assets/img/menuarrow.svg) no-repeat;
  background-size: contain;
  width: 5px;
  height: 9px;
}
.menu-open .navBtn span {
  transform: rotate(180deg);
}
.menu-open {margin-left: -250px;}
.logoWrap {background: #353535;width: 250px;padding: 10px;display: flex;align-items: center;justify-content: space-between;box-sizing:border-box;}
.using-site-info.logo {font-size: 22px;color: #fff;white-space: nowrap;}
.header-lower {
  background: #e1534e;
  color: #fff;
  margin: 0 10px 0;
  font-size: 12px;
}

.header-lower .item {
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 20px;
}

.nav-upper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 24px 0;
  width: 250px;
  box-sizing: border-box;
}

.user-info-wrap.credit-wrap {
  padding: 12px 0;
}

.user-info-wrap.top {
  color: #fff;
}
.user-info-wrap h2 {display: flex;align-items: center;justify-content: space-between;}
.inspect {display: flex;width: 10px;height: 10px;border-radius: 50%;border: 1px solid #000;box-sizing: border-box;}
.inspect.on {background-color: #d51212;}
.inspect.off {background-color: #4ccf36;}
.tempBox-wrap.first {
  border-bottom: 2px solid #486d85;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.user-info-wrap h2, .notification-wrap h2 {
  color: #b3b3b3;
  font-size: 11px;
  font-weight: 800;
  margin: 12px 0;
}
.user-info-wrap {
  box-sizing: border-box;
  width: 100%;
}
.user-info-wrap:last-child {
  margin: 0;
}

.user-info-wrap .title {
}

.user-info-wrap .contents {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mId {
  max-width: 125px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
}

.logout-name {
  font-size: 13px;
}

.logout-link {
  background: none;
  cursor: pointer;
  margin-left: 10px;
}

.logout-link img {
  vertical-align: middle;
  width: 20px;
}

.logout-link:hover {
  /*background: #000;*/
  color: #fff;
}

.img-profile {
  width: 38px;
  border-radius: 100%;
  margin-right: 8px;
}

.wopen-note {
  color: #fff;
  font-weight: 800;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  font-size: 0.8em;
  cursor: pointer;
  user-select: none;
}

.wopen-note img {
  width: 26px;
  margin-bottom: 6px;
}

.notification-wrap {
  margin: 0 10px 3px;
  padding: 10px 15px;
  font-size: 13px;
}

.notification-wrap .noti-item:first-child {
  margin-top: 0;
}

.notification-wrap .noti-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  font-size: 14px;
}

.notification-wrap .noti-item h4 {
  font-size: 14px;
  color: #fff;
  width: 70px;
  height: 22px;
  font-size: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
}
.notification-wrap .noti-item a{
  color: #fff;
  font-size: 15px;
}

.notification-wrap .noti-item a:last-child,
.notification-wrap .noti-item div:last-child {
  margin-left: 8px;
}

.notification-wrap .noti-item a span span {
  color: #97bab8;
  font-weight: bold;
  padding: 0 1px;
}

.notification-wrap .noti-item a:last-child span span {
  color: #ff6f00;
}

.nav-bottom {
  margin: 0 24px 28px 56px;
  font-size: 16px;
}

.nav-bottom > li:last-child .content-wrap.active > .menu-contents {
  border-bottom: 0;
}

nav .content-wrap {
  /*overflow: hidden;*/
}

nav .content-wrap.active {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

nav .content-wrap:not(.active):hover {
}

.active .menu-title { color: #fff; }
.menu-title {
  color: #898989;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 12px 0;
  font-weight: 800;
}
.menu-title:hover .text, .menu-title:hover .fas  {
  color: #fff;
}
nav .content-wrap.active > .menu-title .fas {
  color: #fff;
}
nav .content-wrap.active > .menu-title .fas.fa-chevron-right {
  transform: rotate(270deg);
}
.text-wrap{display: flex; gap: 20px;}
.text-wrap i {display: none; width: 14px; }
.fa-chevron-right {
  font-size: 13px;
  color: #8a8a8c;
  transform: rotate(90deg);
}

.menu-contents {
  max-height: 0;
  overflow: hidden;
  font-size: 14px;
}

nav .content-wrap.active > .menu-contents {
  max-height: 1000px;
  /* overflow: unset; */
  transition: 0.5s;
  padding-left: 5px;
}

.menu-contents li {
  position: relative;
}

.menu-contents .supMenu i {
  padding-right: 2px;
  font-size: 10px;
}

.menu-contents .supMenu.active i {
  color: #eb7a3f;
  transform: rotate(90deg);
}

.menu-contents .supMenu {
  display: flex;
  justify-content: space-between;
  height: 30px;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 13px;
  color: #bfbfbf;
}

.menu-contents .supMenu:hover {
  color: #eb7a3f;
}

.menu-contents .subMenu-wrap {
  max-height: 0;
  overflow: hidden;
  /* background-color: #b77c66; */
}

.menu-contents .subMenu {
  cursor: pointer;
  display: flex;
  height: 30px;
  align-items: center;
  font-weight: 300;
  color: #8a8a8c;
  font-size: 13px;
  width: 100%;
  box-sizing: border-box;
}

.menu-contents .subMenu::before {
  content: "";
  display: block;
  width: 5px;
  height: 1px;
  margin-right: 10px;
  background-color: #8a8a8c;
}

.menu-contents .subMenu:hover::before {
  background-color: #fff !important;
}

.menu-contents .subMenu-wrap.active {
  max-height: 630px;
}

.menu-contents .subMenu-container {
  flex-direction: column;
  align-items: flex-start;
}

.menu-contents a.router-link-exact-active,
.menu-contents a.router-link-active,
.menu-contents .supMenu.active,
.menu-contents .subMenu-title.active {
  color: #ff7e00;
}

.menu-wrap a:hover {
  color: #fff;
}

.menu-contents a.router-link-exact-active:hover,
.menu-contents a.router-link-active:hover,
.menu-contents .subMenu-title.active:hover {
  background: ;
}

.menu-contents a.subMenu.router-link-exact-active::before,
.menu-contents a.subMenu.router-link-active::before {
  background: #d93832;
}

.menu-contents a.subMenu.router-link-exact-active,
.menu-contents a.subMenu.router-link-active {
  color: #d93832;
}
.menu-contents a.subMenu.active {
  color: #fff;
}
.menu-contents a.subMenu.active::before {
  background: #fff;
}
.menu-contents a.subMenu.router-link-exact-active:hover,
.menu-contents a.subMenu.router-link-active:hover {
  color: #d93832;
}

.btn-credit-wrap {
  margin: 15px 0px 10px;
}

.supMenu.done:before {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: green;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}

.credit-wrap {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  flex-direction: column;
}

.credit-wrap .title {
  /*color: #18698b;*/
  font-size: unset;
}

.credit-wrap .content-val {
  font-weight: 800;
  font-size: unset;
}

.tempBox {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.tempBox-wrap.bi {
  border-top: 1px solid #486d85;
  padding-top: 10px;
}

.tempBox-wrap {
}

.tempBox-wrap > div {
  font-size: 13px;
}

.tempBox-wrap.bi .tempBox > div {
  /*color: #cd425b;*/
}

.tempBox-wrap.pt .tempBox > div.title {
  color: #fff;
}
.tempBox-item {display: flex; flex-direction: column; gap: 10px;color: #fff;}
.pt .tempBox {
  color: #ff7e00;
}

.pt .tempBox.point {
  color: #a6c4cd;
}

.bi .tempBox {
  /*color: #c437e7;*/
}

.bi .tempBox.point {
  /*color: #e737a9;*/
}
.hd .tempBox {color: #16c8ff;}

.tempBox-wrap .tempBox > div {
  margin-bottom: 0;
}

.tempBox.point > div {
  font-size: 13px;
}

.tempBox.point > div.content-val {
  font-weight: 500;
}

.btn-credit-apply {
  font-size: 10px;
  box-sizing: border-box;
  border-radius: 2px;
  box-shadow: 8px 8px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #2d5b89;
  color: #fff;
  padding: 5px 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  flex: 1 1 0;
}

.btn-credit-apply:hover {
  font-weight: 700;
}

.modal-title {
border-bottom: solid 4px #d3d3d3;
color: #e57300;
font-size: 24px;
display: flex;
align-items: center;
justify-content: space-between;
padding-bottom: 15px;
box-sizing: border-box;
}

.modal-title .PTtabmenu {
   font-weight: bold;
}

.modal-title .PTtabmenu li.current {
  font-weight: bold;
  box-sizing: border-box;
}

.sub-title {
  font-size: 15px;
  color: #5c5e60;
  border-bottom: 0;
  height: 40px;
}

.modal-item {
  width: 500px;
  margin: 0 auto;
  position: relative;
  margin-top: 350px;
  background: #fff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: 8px solid #bbbbbb;
  padding: 15px 30px;
  color: #5c5e60;
}

.modal-close {
  transform: translate(-50%, 100%);
}

.ui-check-wrap {
  width: fit-content;
  margin-right: 5px;
}
.btn-wrap {
  gap: 5px;
  width: 100%;
  padding: 0;
  height: auto;
  margin-top: 15px;
}

.modalWrap .item-title {
border-radius: 4px;
background-color: #8a8a8a;
font-size: 18px;
color: #fff;
height: 44px;
min-width: 55px;
display: flex;
align-items: center;
padding: 0 20px;
}

.modalWrap .item-title + input {
  text-align: right;
}

.modalWrap .fx-item-auto {
align-items: center;
justify-content: space-between;
box-sizing: border-box;
height: 58px;
border-bottom: 1px solid #8a8a8a;
}

.modal-body {
}

.modal-body input {
   width: 250px;
   background: transparent;
   border: 1px solid #8a8a8a;
   box-sizing: border-box;
   color: #c40000;
   font-size: 20px;
   height: 44px;
}
.modal-body input:focus {border-color: #1d1d1d;}

.modal-body input[type="text"]:disabled {
  border: 0;
  color: #1d1d1d;
}

.modal-body select {
   background: transparent;
   border: 1px solid #d5d5d5;
   box-sizing: border-box;
   color: #000;
   font-size: 16px;
}

.modalNotice {
   color: #c40000;
   font-size: 14px;
}
.btn-close {font-size: 24px;color: #1d1d1d;background: none;cursor: pointer;}
.btn-layout {
  background: #818181;
  width: 250px;
  height: 50px;
  font-size: 24px;
}
.btn-layout:hover {background: #e57300;filter: none;}
.modal2 .modal-title {
   color: #005f39;
   justify-content: space-between;
   align-items: center;
   display: flex;
}
.modal2 .item-title::before {
  color: #82a6a4;
}
.modal2 .item-title {min-width: 145px;}
.modal2 .btn-layout:hover {background: #009358;}

.nomarginbt {
  margin-bottom: 0 !important;
}

/*보유 서브포인트 탭1 에서 안보이게 ㅠ*/
.w700 {
  width: 700px;
}

.mbtnw {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 20px 0 30px;
}

.mbtnw a {
  cursor: pointer;
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #db6464;
  border-radius: 3px;
  color: #fff;
}
.langWrap {position: relative;}
.langWrap .langT {background: none;font-size: 12px;}
.langWrap .langT > span {display: flex;align-items: center;justify-content: center;gap: 5px;color: #fff;}
.langWrap .langT img, .langWrap .langB img {border-radius: 2px;height: 13px;}
.langWrap .langB {border-radius: 2px;padding: 5px;position: absolute;left: -5px;top: 19px;border: 1px solid #486d85;background: #2d5b89;}
.langWrap .langB a {display: flex;align-items: center;justify-content: center;gap: 5px;font-size: 12px;}
.langWrap .langB span {color: #fff;}
.langWrap .langB a:first-child {margin-bottom: 8px;}

.navWrap {display: flex;margin-top: -209px;}
</style>
