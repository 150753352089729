<template>
  <main>
    <router-view :key="$route.fullPath"/>
  </main>
</template>

<script>

export default {
  name: 'InMain',
  props: ['currentMenu']
}
</script>

<style scoped>
main {
  width: 100%;
  /*padding-top: 15px;*/
  margin-bottom:15px;
  box-sizing: border-box;
}
</style>
