<template>
  <article v-if="month10Info">
    <ul class="favorite">
      <li><a @click="bookmarkPopup"><i class="fa fa-star" aria-hidden="true"></i>{{ $t("common.favoritea") }}</a></li>
      <template v-for="item in bookmarkList" :key="item.menuCode">
         <li><a @click="onBookmarkClick(item)">{{item.menuCodeName}}</a></li>
      </template>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </ul>
    <div v-if="favorite" class="favorite-box">
      <h2 class="title">{{ $t("common.favorite") }}<span>({{ $t("txt.limitFive") }})</span></h2>
      <div class="fbox">
        <template v-for="group in allBookmarkList" :key="group.menuGroupCode">
          <ul>
            <li>{{group.menuGroupCodeName}}</li>
            <template v-for="item in group.list" :key="item.menuCode">
              <li>{{item.menuCodeName}}<a @click="onChangeBookmark(item)"><i :class="{ on : item.bookmarkYn === 'Y' }" class="fa fa-star" aria-hidden="true"></i></a></li>
            </template>
          </ul>
        </template>
      </div>
      <div class="btn">
        <a @click="favorite = false">{{ $t("button.close") }}</a>
      </div>
    </div>
  </article>
  <article class="notification-container">
    <!-- <h1 class="using-site-info logo">Moka</h1> -->
    <div class="notification-wrap">
      <div class="noti-item box-3">
        <!-- <span class="title-box">
          <span class="text">{{ $t("betting.title") }}</span>
        </span>
        <router-link :to="{}" class="contents-box">
          <span class="text">{{ $t("betting.totalBetCount") }}</span>
          <span
            >(<span>{{ numberWithCommas(headerTopInfo.betCnt) }}</span
            >)</span
          >
        </router-link>
        <router-link :to="{}" class="contents-box">
          <span class="text">{{ $t("betting.totalBetAmt") }}</span>
          <span
            >(<span>{{ numberWithCommas(headerTopInfo.betAmt) }}</span
            >)</span
          >
        </router-link> -->
        <router-link :to="{ name: 'userInApply', query: { status: 'all', time: new Date().getTime() } }" class="box in" :class="{ 'alert' : model.cashInReqCnt }">
          <em><img src="@/assets/img/icon-top-apply.svg" />{{ $t("button.reDeposit") }}</em>
          <span class="count">{{headerTopInfo.inCashCnt}}</span>
          <router-link :to="{ name: 'userInApply', query: { status: '2', time: new Date().getTime() } }" class="countbox"><i>{{ $t("button.waitincom") }}</i><span class="roboto">{{ alarmInfo.cashInWaitCnt }}</span>
          </router-link>
        </router-link>
        <router-link :to="{ name: 'userSignUpList', query: { status: 'all', time: new Date().getTime() } }" class="box join" :class="{ 'alert' : model.memJoinReqCnt }">
          <em><img src="@/assets/img/icon-top-join.svg" />{{ $t("common.registera") }}</em>
          <span class="count">{{ thousandCount(headerTopInfo.memJoinCnt) }}</span>
          <router-link :to="{ name: 'userSignUpList', query: { status: '2', time: new Date().getTime() } }" class="countbox"><i>{{ $t("common.registerW") }}</i><span class="roboto">{{ alarmInfo.memJoinWaitCnt }}</span></router-link>
        </router-link>
        <router-link :to="{ name: 'userOutApply', query: { status: 'all', time: new Date().getTime() } }" class="box out" :class="{ 'alert' : model.cashOutReqCnt }">
          <em><img src="@/assets/img/icon-top-out.svg" />{{ $t("button.outAppply") }}</em>
          <span class="count">{{ headerTopInfo.outCashCnt }}</span>
          <router-link :to="{ name: 'userOutApply', query: { status: '2', time: new Date().getTime() } }" class="countbox"><i>{{ $t("button.waitout") }}</i><span class="roboto">{{ alarmInfo.cashOutWaitCnt }}</span></router-link>
        </router-link>
        <router-link :to="{ name: 'boardQna', query: { status: 'all', time: new Date().getTime() } }" class="box qna" :class="{ 'alert' : model.qnaReqCnt }">
          <em><img src="@/assets/img/icon-top-qna.svg" />{{ $t("common.qna") }}</em>
          <span class="count">{{ alarmInfo.qnaReqCnt }}</span>
          <router-link :to="{ name: 'boardQna', query: { time: new Date().getTime() } }" class="countbox"><i>{{ $t("common.qnaWait") }}</i><span class="roboto">{{ alarmInfo.qnaWaitCnt }}</span></router-link>
        </router-link>
      </div>
      <div class="noti-item box-1">
        <!-- <span class="title-box">
          <span class="text">{{ $t("common.member") }}</span>
        </span>
        <router-link :to="{ name: 'loginList' }" class="contents-box">
        <a class="contents-box">
           <span class="text">{{ $t("common.login") }}</span>
          <span
            >(<span>{{thousandCount(Number(headerTopInfo.memLoginCnt))}}</span
            >)</span
          >
        </a>
        </router-link>
        <router-link :to="{ name: 'userSignUpList' }" class="contents-box">
          <span class="text">{{ $t("common.newSignup") }}</span>
          <span
            >(<span>{{ thousandCount(headerTopInfo.memJoinCnt) }}</span
            >)</span
          >
        </router-link> -->
        <router-link :to="{ name: 'loginList', query: { status: '0', time: new Date().getTime() } }" class="box">
          <em><img src="@/assets/img/icon-top-on.svg" />{{ $t("header.loginmem") }}</em>
          <span class="count">{{thousandCount(Number(headerTopInfo.memLoginCnt))}}</span>
          <span class="countbox"><i>{{ $t("user.todayNew") }}</i><span class="roboto">{{ thousandCount(headerTopInfo.memJoinCnt) }}</span></span>
        </router-link>
        <!--router-link :to="{ name: 'userSignUpList', query: { status: '0', time: new Date().getTime() } }" class="box">
          <em>{{ $t("header.newmem") }}</em>
          <span class="count">{{ thousandCount(headerTopInfo.memJoinCnt) }}</span>
          <span class="countbox"><i></i></span>
        </router-link-->
        <router-link :to="{ name: 'adminReport', query: { status: '0', time: new Date().getTime() } }" class="box">
          <em><img src="@/assets/img/icon-top-cash.svg" />{{ $t("header.managerPayC") }} <!--({{ headerTopInfo.adminPaymentCnt }})--></em>
          <span class="count">{{ numberWithCommas(headerTopInfo.adminPayment) }}</span>
          <span class="countbox"><i>{{$t("header.managerReturnC")}}</i><span class="roboto">{{ numberWithCommas(headerTopInfo.adminPayback) }}</span></span>
        </router-link>
        <router-link :to="{ name: 'userList', query: { status: '0', time: new Date().getTime() } }" class="box">
          <em><img src="@/assets/img/icon-top-has.svg" />{{ $t("table.head.haveMemM") }}</em>
          <span class="count">{{ numberWithCommas(headerTopInfo.cashAmt) }}</span>
          <span class="countbox"><i>{{ $t("table.head.havePoint") }}</i><span class="roboto">{{ numberWithCommas(headerTopInfo.pointAmt) }}</span></span>
        </router-link>
        <!--router-link :to="{ name: 'userList', query: { status: '0', time: new Date().getTime() } }" class="box">
          <em>{{ $t("header.userPoint") }}</em>
          <span class="count">{{ numberWithCommas(headerTopInfo.pointAmt) }}</span>
          <span class="countbox"><i></i></span>
        </router-link-->
        <router-link :to="{ name: 'adminReport', query: { status: '0', time: new Date().getTime(), searchType: 'point' } }" class="box">
          <div class="toLink"></div>
          <em><img src="@/assets/img/icon-top-pt.svg" />{{ $t("header.managerPayP") }}  <!--({{ headerTopInfo.adminPointInCnt }})--></em>
          <span class="count">{{ numberWithCommas(headerTopInfo.adminPointIn) }}</span>
          <span class="countbox"><i>{{$t("header.managerReturnP")}}</i><span class="roboto">{{ numberWithCommas(headerTopInfo.adminPointOut) }}</span></span>
        </router-link>
      </div>
      <div class="noti-item box-2">
        <!-- <span class="title-box">
          <span class="text">{{ $t("common.dpsWdrow") }}</span>
        </span>
        <a class="contents-box">
          <span class="text">{{ $t("table.head.deposit") }}</span>
          <span
            >(<span :class="{ on: headerTopInfo.inCashAmt !== 0 }">{{ numberWithCommas(headerTopInfo.inCashAmt) }}</span
            >)</span
          >
        </a>
        <a class="contents-box">
          <span class="text">{{ $t("table.head.withdraw") }}</span>
          <span
            >(<span :class="{ on: headerTopInfo.outCashAmt !== 0 }">{{ numberWithCommas(headerTopInfo.outCashAmt) }}</span
            >)</span
          >
        </a> -->
        <div class="box">
          <router-link :to="{ name: 'depositReport', query: { status: '0', time: new Date().getTime() } }" class="link">
            <em><img src="@/assets/img/icon-top-in.svg" height="19px" />{{ $t("header.inAmt") }} <!--({{headerTopInfo.inCashCnt}})--></em>
            <span class="count">{{ numberWithCommas(headerTopInfo.inCashAmt) }}</span>
          </router-link>
          <span class="countbox arrow" @click="modalToggle('cashIN')"><i class="fa" :class="[cashIN? 'fa-caret-up':'fa-caret-down']" aria-hidden="true"></i></span>
          <div v-if="cashIN" class="reportBox">
            <h2>{{ $t("common.deposit") }} TOP 15</h2>
            <div class="toplist">
              <template v-for="(item, idx) in cashIN" :key="item.memId">
                <ul>
                  <li>{{idx + 1}}</li>
                  <li>{{item.memId}}(<i>{{item.memNick}}</i>)</li>
                  <li>{{thousand(item.amount)}}</li>
                </ul>
              </template>
            </div>
            <a @click="cashIN = false" class="btn"><i class="fa fa-caret-up" aria-hidden="true"></i></a>
          </div>
        </div>
        <div class="box">
          <router-link :to="{ name: 'withdrawReport', query: { status: '0', time: new Date().getTime() } }" class="link">
            <em><img src="@/assets/img/icon-top-outm.svg" height="19px" />{{ $t("header.outAmt") }} <!--({{ headerTopInfo.outCashCnt }})--></em>
            <span class="count">{{ numberWithCommas(headerTopInfo.outCashAmt) }}</span>
          </router-link>
          <span class="countbox arrow" @click="modalToggle('cashOUT')"><i class="fa" :class="[cashOUT? 'fa-caret-up':'fa-caret-down']" aria-hidden="true"></i></span>
          <div v-if="cashOUT" class="reportBox">
            <h2>{{ $t("common.withdraw") }} TOP 15</h2>
            <div class="toplist">
              <template v-for="(item, idx) in cashOUT" :key="item.memId">
                <ul>
                  <li>{{idx + 1}}</li>
                  <li>{{item.memId}}(<i>{{item.memNick}}</i>)</li>
                  <li>{{thousand(item.amount)}}</li>
                </ul>
              </template>
            </div>
            <a @click="cashOUT = false" class="btn"><i class="fa fa-caret-up" aria-hidden="true"></i></a>
          </div>
        </div>
        <div class="box">
          <router-link :to="{ name: '', query: { status: '0', time: new Date().getTime() } }" class="link">
            <em>{{ $t("header.inoutAmt") }}</em>
            <span class="count" :class="{'red' : Number(headerTopInfo.inCashAmt-headerTopInfo.outCashAmt) > 0, 'blue': Number(headerTopInfo.inCashAmt-headerTopInfo.outCashAmt) <= 0}">{{ numberWithCommas(headerTopInfo.inCashAmt-headerTopInfo.outCashAmt) }}</span>
          </router-link>
          <span class="countbox arrow" @click="modalToggle('cashBEP')"><i class="fa" :class="[cashBEP? 'fa-caret-up':'fa-caret-down']" aria-hidden="true"></i></span>
          <div v-if="cashBEP" class="reportBox right">
            <h2>{{ $t("header.inoutAmt") }} TOP 10</h2>
            <div class="toplist">
              <template v-for="(item, idx) in cashBEP" :key="item.memId">
                <ul>
                  <li>{{idx + 1}}</li>
                  <li>{{item.memId}}(<i>{{item.memNick}}</i>)</li>
                  <li>{{thousand(item.amount)}}</li>
                </ul>
              </template>
            </div>
            <a @click="cashBEP = false" class="btn"><i class="fa fa-caret-up" aria-hidden="true"></i></a>
          </div>
        </div>
        <div class="box">
          <router-link :to="{ name: 'alltotalList', query: { status: '0', time: new Date().getTime() } }" class="link">
            <em>{{ $t("header.totalBet") }}</em>
            <span class="count">{{ numberWithCommas(headerTopInfo.betAmt) }}</span>
          </router-link>
          <span class="countbox arrow" @click="modalToggle('totalBET')"><i class="fa" :class="[totalBET? 'fa-caret-up':'fa-caret-down']" aria-hidden="true"></i></span>
          <div v-if="totalBET" class="reportBox">
            <h2>{{ $t("header.totalBet") }} TOP 15</h2>
            <div class="tab">
              <a @click="totalBETCurrentTab = 'casino'" :class="{'on': totalBETCurrentTab == 'casino'}">{{ $t("table.head.casino") }}</a>
              <a @click="totalBETCurrentTab = 'slot'" :class="{'on': totalBETCurrentTab == 'slot'}">{{ $t("table.head.slot") }}</a>
              <a @click="totalBETCurrentTab = 'sport'" :class="{'on': totalBETCurrentTab == 'sport'}">{{ $t("table.head.sport") }}</a>
              <a @click="totalBETCurrentTab = 'mini'" :class="{'on': totalBETCurrentTab == 'mini'}">{{ $t("table.head.mini") }}</a>
            </div>
            <div class="toplist">
              <template v-if="totalBET && totalBETCurrentTab == 'casino'">
                <div class="toplist">
                  <template v-for="(item, idx) in casinoBET" :key="item.memId">
                    <ul>
                      <li>{{idx + 1}}</li>
                      <li>{{item.memId}}(<i>{{item.memNick}}</i>)</li>
                      <li>{{thousand(item.amount)}}</li>
                    </ul>
                  </template>
                </div>
              </template>
              <template v-if="totalBET && totalBETCurrentTab == 'slot'">
                <div class="toplist">
                  <template v-for="(item, idx) in slotBET" :key="item.memId">
                    <ul>
                      <li>{{idx + 1}}</li>
                      <li>{{item.memId}}(<i>{{item.memNick}}</i>)</li>
                      <li>{{thousand(item.amount)}}</li>
                    </ul>
                  </template>
                </div>
              </template>
              <template v-if="totalBET && totalBETCurrentTab == 'sport'">
                <div class="toplist">
                  <template v-for="(item, idx) in sportsBET" :key="item.memId">
                    <ul>
                      <li>{{idx + 1}}</li>
                      <li>{{item.memId}}(<i>{{item.memNick}}</i>)</li>
                      <li>{{thousand(item.amount)}}</li>
                    </ul>
                  </template>
                </div>
              </template>
              <template v-if="totalBET && totalBETCurrentTab == 'mini'">
                <div class="toplist">
                  <template v-for="(item, idx) in miniBET" :key="item.memId">
                    <ul>
                      <li>{{idx + 1}}</li>
                      <li>{{item.memId}}(<i>{{item.memNick}}</i>)</li>
                      <li>{{thousand(item.amount)}}</li>
                    </ul>
                  </template>
                </div>
              </template>
            </div>
            <a @click="totalBET = false" class="btn"><i class="fa fa-caret-up" aria-hidden="true"></i></a>
          </div>
        </div>
        <router-link :to="{ name: 'alltotalList', query: { status: '0', time: new Date().getTime() } }" class="box">
          <em>{{ $t("header.totalWin") }}</em>
          <span class="count">{{ numberWithCommas(headerTopInfo.winAmt) }}</span>
          <span class="countbox"><i>{{ $t("header.totalRoll") }}</i><i class="roboto">{{ numberWithCommas(headerTopInfo.rollingAmt) }}</i></span>
        </router-link>
        <div class="box">
          <router-link :to="{ name: 'alltotalList', query: { status: '0', time: new Date().getTime() } }" class="link">
            <em>{{ $t("table.head.betwlt") }}</em>
            <span class="count" :class="{'red' : Number(headerTopInfo.betAmt-headerTopInfo.winAmt-headerTopInfo.rollingAmt) > 0, 'blue': Number(headerTopInfo.betAmt-headerTopInfo.winAmt-headerTopInfo.rollingAmt) <= 0}">{{ numberWithCommas(headerTopInfo.betAmt-headerTopInfo.winAmt-headerTopInfo.rollingAmt) }}</span>
          </router-link>
          <span class="countbox arrow" @click="modalToggle('totalBEP')"><i class="fa" :class="[totalBEP? 'fa-caret-up':'fa-caret-down']" aria-hidden="true"></i></span>
          <div v-if="totalBEP" class="reportBox right">
            <h2>
              {{ $t("table.head.betwlt") }} TOP 10
              <a>{{ $t("common.order") }}</a>
            </h2>
            <div class="toplist">
              <template v-for="(item, idx) in totalBEP" :key="item.memId">
                <ul>
                  <li>{{idx + 1}}</li>
                  <li>{{item.memId}}(<i>{{item.memNick}}</i>)</li>
                  <li>{{thousand(item.amount)}}</li>
                </ul>
              </template>
            </div>
            <a @click="totalBEP = false" class="btn"><i class="fa fa-caret-up" aria-hidden="true"></i></a>
          </div>
        </div>
      </div>
      <!--div class="noti-item box-4">
        <span class="title-box">
          <span class="text">{{ $t("cash.reports") }}</span>
        </span>
        <router-link :to="{}" class="contents-box">
          <span class="text">{{ $t("table.body.money") }}</span>
          <span
            >(<span :class="{ on: headerTopInfo.cashAmt !== 0 }">{{ numberWithCommas(headerTopInfo.cashAmt) }}</span
            >)</span
          >
        </router-link>
        <router-link :to="{}" class="contents-box">
          <span class="text">{{ $t("table.head.pointAmt") }}</span>
          <span
            >(<span :class="{ on: headerTopInfo.pointAmt !== 0 }">{{ numberWithCommas(headerTopInfo.pointAmt) }}</span
            >)</span
          >
        </router-link> ->
        <router-link :to="{ name: 'userList', query: { status: '0', time: new Date().getTime() } }" class="box">
          <em>{{ $t("header.totalmem") }}</em>
          <span class="count">{{ numberWithCommas(headerTopInfo.memCnt) }}</span>
          <span class="countbox"><i></i></span>
        </router-link>
        <router-link :to="{ name: '', query: { status: '0', time: new Date().getTime() } }" class="box">
          <em>{{ $t("header.betmem") }}</em>
          <span class="count">{{ numberWithCommas(headerTopInfo.betUserCnt) }}</span>
          <span class="countbox"><i></i></span>
        </router-link>
        <router-link :to="{ name: 'betGameTotal', query: { status: '0', time: new Date().getTime() } }" class="box">
          <em>{{ $t("header.betCount") }}</em>
          <span class="count">{{ numberWithCommas(headerTopInfo.betCnt) }}</span>
          <span class="countbox"><i></i></span>
        </router-link>
        <router-link :to="{ name: 'alltotalList', query: { status: '0', time: new Date().getTime() } }" class="box">
          <em>{{ $t("header.totalRoll") }}</em>
          <span class="count">{{ numberWithCommas(headerTopInfo.rollingAmt) }}</span>
          <span class="countbox"><i></i></span>
        </router-link>
      </div-->
    </div>
  </article>
  <div class="noti-bottom" v-if="month10Info">
     <div class="noti-wrap red" @click="betMove('/bet/list/all?orderColumn=betWinAmt')">
        <div class="noti-btn">
          <span class="txt">
             <!--i class="fa fa-list-ul" aria-hidden="true"></i-->
             최대 당첨 알림
             <i>{{thousand(headerTopInfo.maxWinAmtCnt || 0)}}</i>
          </span>
          <em class="count">{{thousand(headerTopInfo.maxWinAmt || 0)}}</em>
       </div>
     </div>

     <div class="noti-wrap">
        <div class="noti-btn" @click="isTotal1 = !isTotal1">
          <span class="txt">
             <!--i class="fa fa-list-ul" aria-hidden="true"></i-->
             {{month10KeyName[month10Key[0]]}}
          </span>
          <em class="count">{{ month10Key[0] ? thousand(month10Info[month10Key[0]]) : 0 }}</em>
       </div>
       <div class="noti-open" v-if="isTotal1" @click="isTotal1 = false;">
          <ul>
            <template v-for="(item, value, idx) in month10KeyName" :key="item">
              <li><a @click="setMonthKeySelect(0, value)">{{idx + 1}} {{item}}</a></li>
            </template>
          </ul>
       </div>
     </div>

     <div class="noti-wrap">
        <div class="noti-btn" @click="isTotal2 = !isTotal2">
          <span class="txt">
             <!--i class="fa fa-list-ul" aria-hidden="true"></i-->
             {{month10KeyName[month10Key[1]]}}
          </span>
          <em class="count">{{ month10Key[1] ? thousand(month10Info[month10Key[1]]) : 0 }}</em>
       </div>
       <div class="noti-open" v-if="isTotal2" @click="isTotal2 = false;">
          <ul>
            <template v-for="(item, value, idx) in month10KeyName" :key="item">
              <li><a @click="setMonthKeySelect(1, value)">{{idx + 1}} {{item}}</a></li>
            </template>
          </ul>
       </div>
     </div>

     <div class="noti-wrap">
        <div class="noti-btn" @click="isTotal3 = !isTotal3">
          <span class="txt">
             <!--i class="fa fa-list-ul" aria-hidden="true"></i-->
             {{month10KeyName[month10Key[2]]}}
          </span>
          <em class="count">{{ thousand(month10Info[month10Key[2]]) }}</em>
       </div>
       <div class="noti-open" v-if="isTotal3" @click="isTotal3 = false;">
          <ul>
            <template v-for="(item, value, idx) in month10KeyName" :key="item">
              <li><a @click="setMonthKeySelect(2, value)">{{idx + 1}} {{item}}</a></li>
            </template>
          </ul>
       </div>
     </div>

  </div>
</template>
<script>
import { getSiteData, setLangCookie } from '@/libs/auth-helper'
import { mapState } from 'vuex'
import { thousand, thousandCount } from '@/libs/utils'
import { getBookmarkList, setBookmark } from '@/api/common'
import { monthTop10ForHeader, top15ForHeader } from '@/api/util'
import Cookies from 'js-cookie'

export default {
  name: 'HeaderInfo',
  data () {
    return {
      istotal: false,
      siteId: '',
      headTopInfo: {},
      showMenuLang: false,
      isMenuOpen: false,
      model: {},
      favorite: false,
      mark: false,
      allBookmarkList: {},
      bookmarkList: [],
      cashIN: false,
      cashOUT: false,
      cashBEP: false,
      totalBET: false,
      totalBEP: false,
      totalBETCurrentTab: 'casino',
      casinoBET: false,
      slotBET: false,
      miniBET: false,
      sportsBET: false,
      month10Info: null,
      month10Key: ['userCashIN', 'userCashBEP', 'betBEP'],
      month10KeyName: {
        userCashIN: '월간 입금',
        userCashBEP: '월간 입출손익',
        betBEP: '월간 배팅손익',
        mofJoinCnt: '월간 가입자 수',
        mofAccessCnt: '월간 누적 접속자 수',
        mofCashInUserCnt: '월간 실 입금 유저 수',
        creditPayAMT: '월간 알 구매 금액',
        creditAMT: '월간 알 소비량',
        adminCashPYM: '월간 관리자 지급금액',
        adminCashPYB: '월간 관리자 회수금액'
      },
      isTotal1: false,
      isTotal2: false,
      isTotal3: false
    }
  },
  props: [
    'headInfo'
  ],
  watch: {
    alarmInfo: {
      handler (model, oldModel) {
        if (!oldModel) return
        // const bellKeys = ["memberApply", "inApply", "outApply", "boardCnt", "biSummaryApplyCnt"];
        const bellKeys = ['qnaReqCnt', 'cashInReqCnt', 'cashOutReqCnt', 'memJoinReqCnt', 'insuranceCnt', 'insuranceExchangeCnt', 'creditCnt', 'creditExchangeCnt']

        let trigger = false

        this.model = { ...model }

        bellKeys.forEach((key, idx) => {
          if (this.$refs[key]) {
            this.$refs[key].muted = true

            if (model[key] && model[key] > 0) {
              this.$refs[key].muted = false
              this.$refs[key].volume = 1.0

              this.noticeCount = model[key]
              // this.isNotice = true

              if (key === 'cashInReqCnt') {
                this.noticeField = this.$t('common.deposit')
              }
              if (key === 'cashOutReqCnt') {
                this.noticeField = this.$t('common.withdraw')
              }
              if (key === 'memJoinReqCnt') {
                this.noticeField = this.$t('common.register')
              }
              if (key === 'qnaReqCnt') {
                this.noticeField = this.$t('common.qna2')
              }
              if (key === 'creditCnt') {
                this.noticeField = '알'
              }
              if (key === 'insuranceCnt') {
                this.noticeField = this.$t('common.bibet')
              }

              const audio = document.querySelector('#' + key).play()
              if (audio !== undefined) {
                audio.then(_ => {
                }).catch(error => {
                })
              }
              trigger = true
            }
          }
        })

        // const bodyClass = document.body.className
        // if (trigger) {
        //   if (bodyClass.indexOf('alarm') === -1) {
        //     document.body.className = bodyClass + ' alarm'
        //   }
        // } else {
        //   if (bodyClass.indexOf('alarm') > -1) {
        //     document.body.className = bodyClass.replace('alarm', '').replace(/\s/g, '')
        //   }
        // }

        const header = document.querySelector('header')
        const headerClass = header.className
        if (trigger) {
          if (headerClass.indexOf('alarm') === -1) {
            header.className = headerClass + ' alarm'
          }
        } else {
          if (headerClass.indexOf('alarm') > -1) {
            header.className = headerClass.replace('alarm', '').replace(/\s/g, '')
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  async created () {
    await this.getMonthTop10()
    this.getBookmarkOnlyList()
    this.getAllBookmarkList()

    if (Cookies.get('monthTop10Key')) {
      const list = JSON.parse(Cookies.get('monthTop10Key'))
      console.log(list)
      this.month10Key = [
        ...list
      ]
    }
  },
  methods: {
    betMove (linkTarget) {
      window.open(`${linkTarget}`)
    },
    thousand,
    setMonthKeySelect (idx, key) {
      this.month10Key[idx] = key
      const monthTop10Key = [
        ...this.month10Key
      ]
      console.log(monthTop10Key)
      const listStr = JSON.stringify(monthTop10Key)
      Cookies.set('monthTop10Key', listStr)
      this.istotal = false
    },
    modalToggle (type) {
      if (!this[type]) {
        if (type === 'totalBET') {
          const list = ['casinoBET', 'slotBET', 'miniBET', 'sportsBET']
          for (let i = 0; i < list.length; i++) {
            const itemType = list[i]
            const params = {
              orderType: itemType
            }

            top15ForHeader(params).then(res => {
              console.log(itemType, res)
              if (res.resultCode === '0') {
                this[itemType] = res.data.topList
              }
            })
          }
        } else {
          const params = {
            orderType: type
          }

          top15ForHeader(params).then(res => {
            console.log(res)
            if (res.resultCode === '0') {
              this[type] = res.data.topList
            }
          })
        }
      }
      this[type] = !this[type]
    },
    getMonthTop10 () {
      return new Promise((resolve, reject) => {
        monthTop10ForHeader({}).then(res => {
          if (res.resultCode === '0') {
            this.month10Info = res.data.month10Info
            console.log(this.month10Info)
          }
          resolve()
        })
      })
    },
    bookmarkPopup () {
      if (!this.favorite) {
        this.getAllBookmarkList()
      }
      this.favorite = !this.favorite
    },
    onBookmarkClick (item) {
      const items = this.$router.getRoutes()

      for (let i = 0; i < items.length; i++) {
        const menu = items[i]
        if (menu.meta && menu.meta.code) {
          if (menu.meta.code === item.menuCode) {
            this.$router.push(menu.path)
          }
        }
      }
    },
    onChangeBookmark (item) {
      if (item.bookmarkYn === 'N') {
        if (this.bookmarkList.length >= 5) {
          alert('메뉴 즐겨찾기 최대 저장 갯수는 5개 입니다')
          return false
        }
      }

      const params = {
        menuCode: item.menuCode,
        bookmarkYn: item.bookmarkYn === 'Y' ? 'N' : 'Y'
      }

      setBookmark(params).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          this.getBookmarkOnlyList()
          if (item.bookmarkYn === 'Y') {
            item.bookmarkYn = 'N'
          } else {
            item.bookmarkYn = 'Y'
          }
        }
      })
    },
    getBookmarkOnlyList () {
      const params = {
        isBookmark: 'Y'
      }
      getBookmarkList(params).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          this.bookmarkList = result.data.menuBookmarkList
        }
      })
    },
    getAllBookmarkList () {
      this.allBookmarkList = {}
      const params = {
        isBookmark: 'N'
      }
      getBookmarkList(params).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          const list = result.data.menuBookmarkList

          list.forEach(item => {
            const menuGroupCode = item.menuGroupCode
            if (!this.allBookmarkList[menuGroupCode]) {
              this.allBookmarkList[menuGroupCode] = {
                ...item,
                list: []
              }
            }
            this.allBookmarkList[menuGroupCode].list.push(item)
          })
        }
      })
    },
    thousandCount,

    toggleMenu () {
      this.isMenuOpen = !this.isMenuOpen
    },
    changeLocale (lang) {
      if (this.$i18n.locale === lang) {
        return
      } else {
        this.$i18n.locale = lang
      }
      setLangCookie(lang)
      this.showMenuLang = false
      location.reload()
    }
  },
  computed: {
    ...mapState([
      'headerTopInfo',
      'alarmInfo'
    ]),
    siteInfo () {
      const siteInfoCookie = getSiteData()
      if (siteInfoCookie) {
        return JSON.parse(siteInfoCookie)
      } else {
        return ''
      }
    }
  }
}
</script>

<style scoped>
.logoWrap {background: #353535;width: 220px;padding: 10px;display: flex;align-items: center;justify-content: space-between;box-sizing:border-box;}
.using-site-info.logo {font-weight: 900;font-size: 26px;color: #fff;white-space: nowrap;}
.langWrap {position: relative;}
.langWrap .langT {background: none;}
.langWrap .langT > span {padding: 6px 7px;border-radius: 5px;display: flex;align-items: center;justify-content: center;gap: 10px;color: #fff;}
.langWrap .langB {width: 60px;border-radius: 5px;padding: 6px;position: absolute;top: 30px;border: 1px solid #53545f;background: #353535;}
.langWrap .langB a {display: block;display: flex;align-items: center;justify-content: center;gap: 10px;font-size: 12px;}
.langWrap .langB a:first-child {margin-bottom: 8px;}
.notification-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.credit-wrap {
  width: 220px;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
}
.credit-wrap .title {
  font-weight: 800;
  color: #ffe000;
}
.credit-wrap .content-val {
  font-weight: 800;
  color: #feb869;
}
.notification-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 18px;
  max-width: 1604px;
}
.noti-item {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 14px;
  flex-wrap: wrap;
}
.noti-item:last-child {
  margin-right: 0;
  /*width: 360px;*/
}
.noti-item:last-child span span {
  color: #fff;
}
.noti-item .text {
}
.noti-item:last-child .contents-box {
  color: #fff;
}
.title-box {
  margin-right: 10px;
  background: #484a4e;
  border-radius: 12px;
  padding: 4px 10px;
  color: #fff;
  font-size: 14px;
}
.contents-box {
  padding: 0 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
  color: #fff;
}
.contents-box:last-child {
  margin: 0;
}

.contents-box span span {
}
.content-mono {
  margin-right: 4px;
}
a {
  color: #fff;
}
a:hover {
  font-weight: 800;
}

.menu-open {margin-left: -220px;}
.box{
  width: 200px;
  height: 91px;
  padding: 10px 0 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: #061738;
  font-weight: 800;
  position: relative;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
}
.box-3 .box {width: 180px;}
.box-3 .box .count,
.box-1 .box:first-child .count {font-size: 36px;}
.box-3 {width: 370px;}
.box-1 {width: 410px;}
.box-2 {width: 620px;}
.box-4 {width: ;}
.box em {font-size: 12px;color: #535c6d;display: flex;align-items: center;gap: 5px;}
.box em img {vertical-align: middle;}
.box .count {font-size: 30px;height: 40px;display: flex;align-items: center;font-family: 'Roboto';font-weight: 500;letter-spacing: -1.2px;}
.count.red {color: #ff0000;}
.count.blue {color: #23a4cc;}
.box .countbox {background: #dcdcdc;width: 100%;height: 22px;display: flex;align-items: center;justify-content: space-between;color: #061738;font-size: 11px;padding: 0 15px;box-sizing: border-box;}
.box-3 .countbox {border-top: 2px solid #ffa200;background: #ffe3b2;}
.box-1 .countbox {border-top: 2px solid #45b828;background: #b2dbbf;}
.box-2 .countbox {border-top: 2px solid #72a2ff;background: #d4e3ff;}
.link {width: 100%;display: flex;flex-direction: column;align-items: center;color: #000;}
.arrow {justify-content: center !important;color: #000;font-size: 25px !important;cursor: pointer;}
.reportBox {position: absolute;top: 91px;width: 335px;padding: 11px 23px 3px;box-sizing: border-box;border-radius: 2px;box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);background-color: #fff;z-index: 2;}
.reportBox.right {right: -17px;}
.reportBox > h2 {padding-bottom: 8px;font-size: 19px;color: #6a6a6a;font-weight: 100;display: flex;justify-content: space-between;align-items: center;}
.reportBox > h2 a {font-size: 12px;letter-spacing: -0.48px;;color: #878c93;}
.reportBox .toplist {border-top: solid 1px #a0a6b0;box-sizing: border-box;}
.reportBox .toplist ul {border-bottom: solid 1px #a0a6b0;box-sizing: border-box;height: 28px;display: flex;align-items: center;flex-wrap: wrap;font-size: 12px;letter-spacing: -0.48px;color: #878c93;font-weight: 100;}
.reportBox .toplist ul:nth-child(odd) {background-color: #f2f2f2;}
.reportBox .toplist li:nth-child(1) {width: 10%;}
.reportBox .toplist li:nth-child(2) {width: 50%;justify-content: left;}
.reportBox .toplist li:nth-child(3) {width: 40%;}
.reportBox .toplist li {display: flex;align-items: center;justify-content: center;}
.reportBox .toplist li i {color: #ff7e00;}
.reportBox .btn {color: #000;font-size: 25px;display: flex;justify-content: center;cursor: pointer;}
.reportBox .tab {display: flex;}
.reportBox .tab a {width: 95px;height: 25px;display: flex;align-items: center;justify-content: center;border-radius: 0 10px 0 0;box-sizing: border-box;box-shadow: 0 -1px 6px 0 rgba(0, 0, 0, 0.2);background-color: #fff;margin-left: -25px;font-size: 14px;color: #979797;font-weight: 100;cursor: pointer;}
.reportBox .tab a.on {background-image: linear-gradient(to left, #ffa354 66%, #cf6000);color: #fff;}
.reportBox .tab a:nth-child(1) {margin: 0;width: 70px;z-index: 3;}
.reportBox .tab a:nth-child(2) {z-index: 2;}
.reportBox .tab a:nth-child(3) {z-index: 1;}
.reportBox .tab a + a {padding-left: 25px;}

.toLink {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  top: 0;
  left: 0;
}
.favorite {
  width: 160px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.favorite li {
  height: 28px;
  font-size: 14px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #e4e4e4;
  background-color: #fff;
  box-sizing: border-box;
  cursor: pointer;
}
.favorite li + li + li + li + li + li + li {display: none;}
.favorite li a {color: #061738;height: 28px;display: flex;align-items: center;justify-content: center;gap: 8px;}
.favorite .fa {color: #ffa200;}
.favorite li:nth-child(1) {z-index: 5;border-color: #ffd07f;}
.favorite li:nth-child(2) {z-index: 4;}
.favorite li:nth-child(3) {z-index: 3;}
.favorite li:nth-child(4) {z-index: 2;}
.favorite li:nth-child(5) {z-index: 1;}
.isFixed .favorite {gap: 0;margin-top: 18px;}
.isFixed .favorite li {margin-top: -20px;}

.favorite-box {box-shadow: 6px 6px 6px 0 rgba(0, 0, 0, 0.16);border: solid 1px #c1c1c1;background-color: #fafafa;position: absolute;left: 50%;top: 100%;transform: translate(-50%, 0);padding: 15px 26px;z-index: 1;display: flex;flex-direction: column;gap: 10px;min-width: 850px;}
.favorite-box .title {border-bottom: 2px solid #959595;display: flex;align-items: center;padding-bottom: 10px;color: #646464;font-size: 13px;font-weight: 800;display: flex;align-items: center;gap: 5px;}
.favorite-box .title span {font-size: 11px;font-weight: normal;color: #f70000;}
.favorite-box .fbox {display: flex;flex-wrap: wrap;gap: 20px 50px;flex-direction: column;height: 400px;}
.favorite-box .fbox ul {display: flex;flex-direction: column;gap: 7px;color: #343434;font-size: 12px;}
.favorite-box .fbox li {display: flex;justify-content: space-between;align-items: center;width: 130px;}
.favorite-box .fbox li a {cursor: pointer;}
.favorite-box .fbox .fa.on {color: #ffa200;}
.favorite-box .fbox .fa {color: #959595;}
.favorite-box .fbox li:first-child {font-weight: 800;}
.favorite-box .second {font-weight: 800;margin: 5px 0;color: #646464;}
.favorite-box .btn {display: flex;align-items: center;justify-content: center;gap: 10px;margin-top: 20px;}
.favorite-box .btn a {width: 90px;height: 26px;border-radius: 2px;border: solid 1px #646464;box-sizing: border-box;background-color: #fafafa;font-size: 13px;color: #343434;display: flex;align-items: center;justify-content: center;cursor: pointer;}
.favorite-box .btn a:hover {background-color: #646464;color: #fff;}

.noti-bottom {position: absolute;bottom: -36px;right: 17px;display: flex;align-items: center;gap: 10px;}
.noti-bottom .noti-btn {border-radius: 6px;box-shadow: 6px 6px 6px 0 rgba(0, 0, 0, 0.1);background-image: linear-gradient(to bottom, #82a4e6, #395b9b 50%, #1b2c4b);padding: 4px 4px 4px 7px;color: #fff;font-size: 12px;width: 200px;display: flex;align-items: center;justify-content: space-between;box-sizing: border-box;cursor: pointer;position: relative;z-index: 1;}
.noti-bottom .txt {display: flex;align-items: center;gap: 5px;font-size: 11px;font-weight: 100;}
.noti-bottom .count {width: 88px;height: 17px;border-radius: 10px;background-color: #1d437d;padding: 0 5px 0 15px;box-sizing: border-box;display: flex;align-items: center;justify-content: end;font-size: 11px;}
.noti-wrap {position: relative;}
.noti-open {padding-top: 20px;border: solid 1px #c9c9c9;border-top: 0;box-sizing: border-box;background-color: #fff;position: absolute;top: 5px;left: 0;width: 100%;}
.noti-open li {height: 26px;padding: 0 15px;box-sizing: border-box;cursor: pointer;}
.noti-open li:nth-child(even) {border-top: solid 1px #aac6fa;border-bottom: solid 1px #aac6fa;background-color: #f3f7ff;}
.noti-open li:hover a {font-weight: bold;}
.noti-open a {font-size: 11px;color: #434343;}

.noti-bottom .red {margin-right: 8px;}
.noti-bottom .red .noti-btn {background-image: linear-gradient(to bottom, #e68282, #9b3939 50%, #4b1b1b);}
.noti-bottom .red .txt i {width: 17px;height: 17px;border-radius: 50%;background-color: #7d1d1d;display: flex;align-items: center;justify-content: center;font-size: 11px;}
.noti-bottom .red .count {background-color: #7d1d1d;}

.in.alert {animation: animatein infinite 2s ease-in-out;}
.out.alert {animation: animateout infinite 2s ease-in-out;}
.join.alert {animation: animatejoin infinite 2s ease-in-out;}
.qna.alert {animation: animateqna infinite 2s ease-in-out;}
@keyframes animatein {
  0% {
    background: #627afc15;
  }
  50% {
    background: #627afc45;
  }
  100% {
    background: #627afc15;
  }
}
@keyframes animateout {
  0% {
    background: #ff5de815;
  }
  50% {
    background: #ff5de845;
  }
  100% {
    background: #ff5de815;
  }
}
@keyframes animatejoin {
  0% {
    background: #d4693315;
  }
  50% {
    background: #d4693345;
  }
  100% {
    background: #d4693315;
  }
}
@keyframes animateqna {
  0% {
    background: #5ec85a15;
  }
  50% {
    background: #5ec85a45;
  }
  100% {
    background: #5ec85a15;
  }
}
</style>
