export default {
  name: 'Partner',
  path: '/partner',
  component: () => import('@/views/member/partner'),
  redirect: {
    name: 'partnerList'
  },
  meta: {
    layout: 'default',
    isActive: false,
    text: '파트너 관리',
    icon: 'fas fa-users-cog',
    code: 'LV060000'
  },
  children: [
    {
      name: 'partnerList',
      path: '/partner/list',
      component: () => import('@/views/member/partner/pages/list'),
      meta: {
        menuMember: true,
        text: '파트너 목록',
        done: true,
        code: 'LV060100'
      }
    },
    {
      name: 'partnerList2',
      path: '/partner/list2',
      component: () => import('@/views/member/partner/pages/list2'),
      meta: {
        menuMember: true,
        text: '파트너 목록',
        done: true,
        code: 'LV060500'
      }
    },
    {
      name: 'partnerCreate',
      path: '/partner/partnerCreate',
      component: () => import('@/views/member/partner/pages/partnerCreate'),
      meta: {
        menuMember: true,
        text: '파트너 생성',
        code: 'LV060600'
      }
    },
    {
      name: 'partnerCreateView',
      path: '/partner/partnerCreateView',
      component: () => import('@/views/member/partner/pages/partnerCreate'),
      meta: {
        layout: 'popup',
        menuMember: true,
        text: '파트너 생성'
      }
    },
    {
      name: 'partnerDetail',
      path: '/partner/partnerDetail',
      component: () => import('@/views/member/partner/pages/partnerdetail.vue'),
      meta: {
        menuMember: true,
        text: '파트너 상세',
        code: 'LV060700'
      }
    },
    {
      name: 'partnerDetailView',
      path: '/partner/partnerDetailView',
      component: () => import('@/views/member/partner/pages/partnerdetail.vue'),
      meta: {
        layout: 'popup',
        menuMember: true,
        text: '파트너 상세'
      }
    },
    // {
    //   name: 'PartnerTermReport',
    //   path: '/partner/reportTerm',
    //   component: () => import('@/views/member/partner/pages/report_term'),
    //   meta: {
    //     menuMember: true,
    //     text: '파트너 기간별 정산내역'
    //   }
    // },
    {
      name: 'PartnerDetailReport',
      path: '/partner/reportDetail',
      component: () => import('@/views/member/partner/pages/report_detail'),
      meta: {
        menuMember: true,
        text: '파트너 정산 상세내역',
        code: 'LV060200'
      }
    },
    {
      name: 'PartnerSetting',
      path: '/partner/setting',
      component: () => import('@/views/member/partner/pages/setting'),
      meta: {
        menuMember: true,
        text: '파트너 설정',
        code: 'LV060300'
      }
    },

    {
      name: 'PartnerSummary',
      path: '/partner/summary',
      component: () => import('@/views/member/partner/pages/summary'),
      meta: {
        menuMember: true,
        text: '파트너 포인트 요약',
        code: 'LV060400'
      }
    },
    {
      name: 'PartnerPayout',
      path: '/partner/payout',
      component: () => import('@/views/member/partner/pages/payout'),
      meta: {
        menuMember: true,
        text: '공지사항'
        // code: 'LV060500'
      }
    }
  ]
}
