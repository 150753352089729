import { createStore } from 'vuex'
import { IS_PRODUCTION } from './libs/constants'
import { getAuthToken, getSiteData } from './libs/auth-helper'
import { gameGroupCodes, getMenuList } from '@/api/common'
import { siteOption } from '@/api/member'
const headCashModel = {
  allAmt: 0,
  beneAmt: 0,
  biAmt: 0,
  biSummaryApplyCnt: 0,
  creditAmt: 0,
  creditCnt: 0,
  creditExchangeCnt: 0,
  creditSubPoint: 0,
  insuranceCnt: 0,
  insuranceExchangeCnt: 0,
  days: '',
  inAmt: 0,
  insuredAmt: 0,
  insuredSubPoint: 0,
  outAmt: 0,
  pointAmt: 0,
  siteId: '',
  tatalGameMoney: 0,
  yallAmt: 0,
  ypointAmt: 0
}
export default createStore({
  state: {
    // vue의 data객체와 같음
    errorData: null,
    siteData: null,
    headCashInfo: headCashModel,
    gameList: {},
    gameGroupCodes: null,
    gameGroupList: [],
    menuData: null,
    isPT: false,
    isInsured: false,
    isHoldem: false,
    headerTopInfo: {},
    alarmInfo: {},
    siteOption: {}
  },
  getters: {
    // vue의 computed와 같이 계산된 state의 값을 가져옴
    siteData (state) {
      return state.siteData
    },
    headCashInfo (state) {
      return state.headCashInfo
    },
    gameList (state) {
      return state.gameList
    },
    menuData (state) {
      return state.menuData
    }
  },
  mutations: {
    setHeaderTopInfo (state, value) {
      state.headerTopInfo = value
    },
    setAlarmInfo (state, value) {
      state.alarmInfo = value
    },
    // state 값 변경 이벤트 로직 또는 메소드 ..commit()으로 동작, state의 상태변경을 위한 동기처리 로직을 담는다.
    setSiteData (state, value) {
      // console.log(state);
      state.siteData = value
    },
    setHeaderInfo (state, info) {
      const headCashInfo = JSON.parse(JSON.stringify(state.headCashInfo))
      state.headCashInfo = Object.assign(headCashInfo, info)
      // for (const key in state.headCashInfo) {
      //   state.headCashInfo[key] = info[key];
      // }
    },
    setErrorData (state, payload) {
      state.errorData = payload
    },
    clearAllSiteData (state) {
      state.siteData = null
      state.headCashInfo = headCashModel
    },
    setGameList (state, gamesObj) {
      state.gameList = gamesObj
    },
    setMenuData (state, value) {
      state.menuData = value
    },
    setIsPT (state, value) {
      state.isPT = value
    },
    setIsInsured (state, value) {
      state.isInsured = value
    },
    setIsHoldem (state, value) {
      state.isHoldem = value
    },
    setGameGroupCodes (state, value) {
      const categoryOnly = []
      for (const i in value) {
        categoryOnly.push(value[i].groupCode)
      }
      this.state.gameGroupList = categoryOnly
      this.state.gameGroupCodes = value
    },
    setSiteOption (state, value) {
      state.siteOption = value
    }
  },
  actions: {
    storeHeaderTopInfo ({ commit }, payload) {
      commit('setHeaderInfo', payload)
    },
    storeAlarmInfo ({ commit }, payload) {
      commit('setAlarmInfo', payload)
    },
    // store에 메소드를 등록하는 용도, state의 상태변경을 위한 비동기 처리 로직을 담는다.
    storeSiteData ({ dispatch, commit }, payload) {
      const authToken = getAuthToken()
      if (authToken) {
        const siteData = JSON.parse(getSiteData())
        commit('setSiteData', siteData)
      }
    },
    storeSiteOption ({ dispatch, commit }, payload) {
      const siteInfoCookie = getSiteData()
      let siteInfo = null
      if (siteInfoCookie) {
        siteInfo = JSON.parse(siteInfoCookie)
      }
      if (siteInfo) {
        return siteOption({ siteId: siteInfo.siteId }).then(res => {
          if (res.resultCode === '0') {
            const data = res.data

            commit('setSiteOption', data.option)
          }
        })
      }
    },
    inputHeadCashInfo ({ commit }, headInfo) {
      commit('setHeaderInfo', headInfo)
    },
    storeGameGroupCodes ({ dispatch, commit }, payload) {
      payload = {
        masterCode: 'kplay',
        siteId: this.state.siteData.siteId
      }
      if (this.state.gameGroupCodes) {
        return this.state.gameGroupCodes
      } else {
        return gameGroupCodes(payload).then(response => {
          const data = response.data
          commit('setGameGroupCodes', data.data.groupCodes)
          return data
        })
      }
    },
    async storeMenuDate ({ dispatch, commit }, payload) {
      await getMenuList({}).then(res => {
        const menuObj = {}

        for (let i = 0, iLen = res.length; i < iLen; i++) {
          const menuItem = res[i]
          const code = menuItem.menuCode
          if (!menuObj[code]) {
            if (menuItem.useYn === 'Y') {
              menuObj[code] = menuItem
              if (code === 'LV000001') {
                this.LV000001 = true
                commit('setIsPT', true)
              }
              if (code === 'LV000002') {
                this.LV000002 = true
                commit('setIsInsured', true)
              }
              if (code === 'LV000003') {
                this.LV000003 = true
                commit('setIsHoldem', true)
              }
            }
          }
        }

        commit('setMenuData', menuObj)
      })
    }
  },
  strict: IS_PRODUCTION
})
