<template>
  <div class="modalWrap" v-if="isOpen">
    <div class="modal-item" >
      <div class="modal-title">
        <div>
          <ul style="display: flex; cursor: pointer" class="PTtabmenu">
            <li data-tab="tab-1" @click="type = 'pt'" :class="{ current: type === 'pt' }">
              <h3>{{$t('notification.ptApply')}}</h3>
            </li>
            <!--li class="p5-10" data-tab="tab-2" @click="type = 'subPoint'" :class="{ current: type === 'subPoint' }">
              <h3>서브포인트 사용</h3>
            </li-->
          </ul>
        </div>
        <button class="btn-close" type="button" @click="modalClose"><i class="fa fa-times" aria-hidden="true"></i></button>
      </div>
      <div class="modal-body">
        <article class="fx-item-auto fx gap-5">
          <div class="item-title bg-bl">{{$t('notification.holdingPT')}}</div>
          <input type="text" :value="numberWithCommas(modelData.creditAmt)" disabled />
        </article>
        <article class="fx-item-auto fx gap-5" v-if="type === 'pt'">
          <div class="item-title bg-or" >
            <p id="pt" :key="'pt'">{{$t('notification.applyPT')}}</p>
          </div>
          <input type="text" ref="creditApplyInput" v-model="creditAmt" />
        </article>
        <article class="fx-item-auto fx gap-5" v-if="type === 'subPoint'">
          <div class="item-title bg-sb">{{$t('notification.holdingSub')}}</div>
          <input type="text" :value="numberWithCommas(modelData.creditSubPoint)" disabled />
          <div>
            <select v-model="subPointType">
              <option :value="'2'" :selected="true">{{ $t('common.pt') }}</option>
              <option :value="'3'">{{ $t('table.body.cash') }}</option>
            </select>
          </div>
        </article>
      </div>
      <div class="btn-wrap">
        <button class="btn-layout btn-blue" type="button" @click="creditApply">{{$t('common.request')}}</button>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex'
import { creditChargeApply, creditChargeApplySubPoint } from '@/api/member'

export default {
  name: 'PTApply',
  data () {
    return {
      isOpen: false,
      modelData: {},
      type: 'pt', // subpoint,

      subPointType: '2',
      creditAmt: '0'
    }
  },
  computed: {
    ...mapState([
      'alarmInfo',
      'siteOption'
    ])
  },
  watch: {
    creditAmt () {
      const parts = this.creditAmt.split('.')
      const v = parts[0].replace(/\D/g, '')
      const dec = parts[1]
      const calcNum = Number((dec !== undefined ? v + '.' + dec : v))
      let n = new Intl.NumberFormat('en-EN').format(v)
      n = dec !== undefined ? n + '.' + dec : n
      this.creditAmt = n
    }
  },
  methods: {
    modalClose () {
      this.creditAmt = '0'
      this.subPointType = '2'
      this.modelData = {}
      this.type = 'pt'
      this.emitter.emit('openPTApply', false)
    },
    async creditApply () {
      const creditAmt = Number(this.creditAmt.replace(/,/g, ''))
      console.log(creditAmt)
      if (this.type === 'pt') {
        if (creditAmt === null || creditAmt === undefined) {
          return
        } else if (creditAmt <= 0) {
          alert('최소 충전 PT은 0 이상이어야 합니다.')
          return
        } else if (isNaN(creditAmt)) {
          alert('숫자만 작성해주세요.')
          return
        }
      }

      const param = {}
      let confirmMessage = ''

      if (this.type === 'pt') {
        param.creditAmt = creditAmt / Number(this.siteOption.cashDecimal)
        confirmMessage = `${creditAmt}PT을 요청하시겠습니까?`
      }

      if (this.type === 'subPoint') {
        param.creditType = this.subPointType
        confirmMessage = '서브포인트 사용을 요청하시겠습니까?'
      }

      console.log(param)

      const trigger = confirm(`${confirmMessage}`)
      if (trigger) {
        let result
        if (this.type === 'pt') {
          result = await creditChargeApply(param)
        } else {
          result = await creditChargeApplySubPoint(param)
        }

        console.log(result)

        if (result.resultCode === '0') {
          alert('알 신청완료')
          this.modalClose()
        } else {
          alert(result.resultMessage)
        }
      }
      this.creditAmt = '0'
    }
  },
  mounted () {
    this.emitter.on('openPTApply', (value) => {
      console.log('openPTApply', value)
      this.isOpen = value

      this.modelData = {
        creditAmt: this.alarmInfo.creditAmt,
        creditSubPoint: this.alarmInfo.creditSubPoint
      }

      document.body.style.overflow = value ? 'hidden' : 'inherit'
    })
    // document.body.style.overflow = 'hidden'
  }
}
</script>

<style scoped>
input[type="text"] {
}
.modal-item {
  width: 500px;
  position: fixed;
  background: #fff;
  color: #5c5e60;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: 8px solid #bbbbbb;
  padding: 15px 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999999;
}
.btn-wrap {
  gap: 5px;
  width: 100%;
  padding: 0;
  height: auto;
  margin-top: 15px;
}
.btn-close {font-size: 24px;color: #1d1d1d;background: none;cursor: pointer;}
.btn-layout {
  background: #818181;
  width: 250px;
  height: 50px;
  font-size: 24px;
}
.btn-layout:hover {background: #e57300;filter: none;}

.modal-title {
border-bottom: solid 4px #d3d3d3;
color: #e57300;
font-size: 24px;
display: flex;
align-items: center;
justify-content: space-between;
padding-bottom: 15px;
box-sizing: border-box;
}

.modal-title .PTtabmenu {
}

.modal-title .PTtabmenu li.current {
  font-weight: bold;
  box-sizing: border-box;
}

.modalWrap .item-title {
border-radius: 4px;
background-color: #1a336c;
font-size: 18px;
color: #fff;
height: 44px;
min-width: 55px;
display: flex;
align-items: center;
padding: 0 20px;
}

.modalWrap .item-title + input {
  text-align: right;
}

.modalWrap .fx-item-auto {
align-items: center;
justify-content: space-between;
box-sizing: border-box;
height: 58px;
border-bottom: 1px solid #8a8a8a52;
}

.modal-body {
}

.modal-body input {
   width: 250px;
   background: transparent;
   border: 1px solid #8a8a8a52;
   box-sizing: border-box;
   color: #f06854;
   font-size: 20px;
   height: 44px;
}
.modal-body input:focus {border-color: #1d1d1d;}
.modal-body input[type="text"]:disabled {
  border: 0;
  color: #1d1d1d;
}

.modal-body select {
   background: transparent;
   border: 1px solid #d5d5d5;
   box-sizing: border-box;
   color: #000;
   font-size: 16px;
}

.w700 {
  width: 700px;
}
</style>
