export const IS_PRODUCTION = process.env.NODE_ENV === 'production'

export const HTTP_HEADER_SKIP_ERROR = 'UT-Skip-Error'
export const HTTP_STATUS = {
  badRequest: 400,
  unauthrized: 401,
  unprocessableEntity: 422
}
export const RATE_INCREASE = 0.05

export const AUTH_COOKIE = {
  name: 'cms_auth_token',
  expires: (1 / 1440 * process.env.VUE_APP_LOGIN_TIME) || (1 / 12),
  path: '/'
}
export const STORAGE_ITEM = {
  time: 'keyExpireDate'
}

export const LANG_COOKIE = {
  name: 'cms_lang',
  path: '/'
}

export const SOCK_COOKIE = {
  name: 'cms_sock_token',
  path: '/'
}
export const SITE_INFO = {
  name: 'cms_site_info',
  path: '/'
}

export const DOCUMENT_TITLE_PREFIX = 'OMS'

export const GAME_CODE_NAME = {
  kp: '카지노게임',
  kps: '슬롯게임'
}
export const MINIGAME_OPTION_GROUP_INFO = {
  powerball: {
    n: ['narea', 'noe', 'nuo'],
    nc: ['ncarea', 'ncoe'],
    p: ['parea', 'pnum', 'puo', 'poe'],
    pc: ['pcoeuo']
  }
}
export const GAME_INFO_LIST = {
  casino: {
    1: 'evol',
    10: 'cn_pp',
    12: 'playtech',
    13: 'hotel',
    2: 'big',
    5: 'aisa',
    6: 'dream',
    9: 'sexy'
  },
  slot: {
    200: 'slot_pp',
    201: 'slot_habanero',
    202: 'slot_elysium',
    204: 'slot_quick',
    205: 'slot_spade',
    207: 'slot_ray',
    208: 'slot_world',
    209: 'slot_micro',
    212: 'slot_yl',
    213: 'slot_evo',
    214: 'slot_netant',
    215: 'slot_dragon',
    216: 'slot_gg',
    217: 'slot_boongo',
    218: 'slot_playson',
    219: 'slot_playtech',
    220: 'slot_cq9',
    221: 'slot_sky'
  },
  sport: {
    sp: 'sp'
  },
  minigame: {
    mg: 'mg',
    kino: 'kino',
    powerball: 'powerball'
  },
  fishing: {
    1000: 'fishing'
  },
  kiron: {
    102: 'kiron'
  },
  esport: {
    101: 'esport'
  }
}

export const GAME_LOBBY_CODE = {
  slot: '0',
  fishing: '1000'
}

export const PARTNER_TYPE = {
  senior: '시니어',
  master: '마스터',
  agency: '에이전시'
}

export const RATE_MODEL = {
  gameType: '',
  pointRate: '0.00',
  useYn: 'Y',
  betYn: 'Y'
}

export const SOCKET_SUBSCRIBE_DESTINATION = '/topic/'

export const CMS_USER_LIST_SELECT_OPTION = 'CMS_USER_LIST_SELECT_OPTION'

export const PARTNER_LEVEL = {
  PTN_1: '대본',
  PTN_2: '본사',
  PTN_3: '부본',
  PTN_4: '총판',
  PTN_5: '매장',
  NORMAL: '회원'
}

export const TOP_PARTNER_LEVEL = {
  PTN_1: '대본',
  PTN_2: '본사',
  PTN_3: '부본',
  PTN_4: '총판',
  PTN_5: '매장',
  NORMAL: '회원'
}

export const SPORT_BRANCH_LIST = {
  1: '축구',
  2: '농구',
  3: '미식축구',
  6: '테니스',
  7: '야구',
  8: '아이스하키',
  9: '스페셜',
  10: '핸드볼',
  14: 'Motor Racing',
  17: 'Poker',
  23: 'Hockey',
  19: '배구',
  34: '배드민턴',
  43: 'MMA',
  45: '수영',
  64: 'E-스포츠',
  218: '가상축구',
  219: '가상농구',
  220: '롤',
  221: 'DOTA2',
  222: 'CSGO',
  0: '기타'
}

export const STATUS_LIST = {
  Opened: '경기시작 전',
  Draw: '무승부',
  Won: '승리',
  Lost: '패배',
  Cancel: '취소',
  Cashout: '캐시아웃'
}
