<template>
  <header>
    <div class="w250"></div>
    <div class="header-upper">
      <!-- <a href="/user/list" ><h1 class="using-site-info logo">{{siteInfo.siteName}}</h1></a> -->
      <noti-company />
    </div>
  </header>
</template>

<script>
import notiCompany from '@/components/main/header/Noti_company'
import { getSiteData } from '@/libs/auth-helper'
import { headerTopInfo } from '@/api/member'
import store from '@/store'
export default {
  name: 'HeaderArea',
  data() {
    return {
      showMenuLang: false
    }
  },
  components: {
    notiCompany
  },
  methods: {
    getSiteName () {
      const siteInfoCookie = getSiteData()
      if (siteInfoCookie) {
        const siteInfo = JSON.parse(siteInfoCookie)
        return siteInfo
      }
    },
    changeLocale (lang) {
      if (this.$i18n.locale === lang) {
        return
      } else {
        this.$i18n.locale = lang
      }
      setLangCookie(lang)
      this.showMenuLang = false
      location.reload()
    }
  },
  data () {
    return {
    }
  },
  async created () {
    this.siteInfo = this.getSiteName()

    const res2 = await headerTopInfo()
    if (res2.resultCode === '0') {
      store.commit('setHeaderTopInfo', res2.data.headTopInfo)
    }
  }
}
</script>

<style scoped>
.w250{
  height: 100%;
  width: 250px;
  min-width: 250px;
}
.mId {
  font-weight: 800;
  color: #000;
  font-size: 16px;
  max-width: 125px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.logo a {
  color: #fff;
}
.using-site-info.logo {font-weight: 900;font-size: 20px;color: #fff;white-space: nowrap;}
.langs-wrap {
  display: flex;
  justify-content: space-between;
  align-content: center;
  color: #fff;
}
.langs-wrap .title {
  font-weight: 700;
  margin-right: 6px;
  font-size: 0.875em;
}
.selLang {
  height: 18px;
}
.langWrap {position: relative;}
.langWrap .langT {background: none;}
.langWrap .langT > span {padding: 6px 7px;border-radius: 5px;display: flex;align-items: center;justify-content: center;gap: 5px;color: #fff;}
.langWrap .langT img {border-radius: 7px;}
.langWrap .langB {width: 60px;border-radius: 5px;padding: 6px;position: absolute;top: 30px;border: 1px solid #53545f;background: #353535;}
.langWrap .langB a {display: block;display: flex;align-items: center;justify-content: center;gap: 10px;font-size: 12px;}
.langWrap .langB span {color: #fff;}
.langWrap .langB a:first-child {margin-bottom: 8px;}
.langbox {padding: 4px 7px; background: #202020; color: #fff; border-radius: 7px; display: flex; align-items: center; justify-content: center; font-size: 10px;}
.btn-credit-apply {
  font-size: 0.75em;
  border: 1px solid #18698b;
  box-sizing: border-box;
  background: #18698b;
  color: #fff;
  padding: 3px 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  flex: 1 1 0;
}
.btn-credit-apply:hover {
  color: #18698b;
  background: #fff;
}
.user-info-wrap {
  box-sizing: border-box;
  width: 200px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.user-info-wrap:last-child {
  margin: 0;
}
.user-info-wrap .title {
  font-size: 0.8em;
  color: #000;
  font-weight: 700;
}
.user-info-wrap .contents {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (min-width: 2000px) {
  .header-upper {justify-content: flex-end;}
}
</style>
