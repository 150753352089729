import httpClient from '@/libs/http-client'

const nullToQuote = (data) => {
  return !data ? '' : data
}

export function getCode (masterCode, groupCode, code) {
  const url = '/api/common/code'

  const data = {
    masterCode: nullToQuote(masterCode),
    groupCode: nullToQuote(groupCode),
    code: nullToQuote(code)
  }

  return httpClient.post(url, data).then(response => {
    return response.data.data.codes
  })
}

export function getMenuList () {
  const url = '/api/admin/getMenuList'

  return httpClient.post(url, {}).then(response => {
    return response.data.data.menuList
  })
}

export function getVendorList () {
  const url = '/api/admin/getVendorList'

  return httpClient.post(url, {}).then(response => {
    return response.data.data.vendorList
  })
}

export function gameGroupCodes (params) {
  const url = '/api/admin/gameGroupCodes'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getDashboard (params) {
  const url = '/api/admin/dashInfo'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getRateCategory () {
  const url = '/api/common/rateCategory'
  return httpClient.post(url, {}).then(response => {
    return response
  })
}

export function getBookmarkList (params) {
  const url = '/api/admin/getMenuBookmarkList'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function setBookmark (params) {
  const url = '/api/admin/updBookmark'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getMemo (params) {
  const url = '/api/admin/getSiteMemo'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function setMemo (params) {
  const url = '/api/admin/updateSiteMemo'
  return httpClient.post(url, params).then(response => {
    return response
  })
}
