export default {
  name: 'reports',
  path: '/reports',
  component: () => import('@/views/member/reports'),
  meta: {
    layout: 'default',
    isActive: false,
    text: '정산관리',
    icon: 'fas fa-list-alt',
    code: 'LV080000'
  },
  children: [
    {
      name: 'alltotalList',
      path: '/reports/alltotalList',
      component: () => import('@/views/member/reports/pages/alltotalList'),
      meta: {
        menuMember: true,
        text: '파트너정산내역',
        code: 'LV080800'
      }
    },
    {
      name: 'alltotalList2',
      path: '/reports/alltotalList2',
      component: () => import('@/views/member/reports/pages/alltotalList2'),
      meta: {
        menuMember: true,
        text: '파트너정산내역(날짜별)',
        code: 'LV080600'
      }
    },
    {
      name: 'usertotalList',
      path: '/reports/usertotalList',
      component: () => import('@/views/member/reports/pages/usertotalList'),
      meta: {
        menuMember: true,
        text: '회원정산내역',
        code: 'LV080900'
      }
    },
    {
      name: 'memWinLose',
      path: '/reports/winlose',
      component: () => import('@/views/member/reports/pages/winlose'),
      meta: {
        menuMember: true,
        text: '회원승패',
        code: 'LV080100'
      }
    },
    {
      name: 'termsReport',
      path: '/reports/termsReport',
      component: () => import('@/views/member/reports/pages/termsReport'),
      meta: {
        menuMember: true,
        text: '기간별 현황',
        code: 'LV080200'
      }
    },
    {
      name: 'gamesReport',
      path: '/reports/gamesReport',
      component: () => import('@/views/member/reports/pages/gamesReport'),
      meta: {
        menuMember: true,
        text: '게임별 리포트',
        code: 'LV080300'
      }
    },
    {
      name: 'dailyReport',
      path: '/reports/dailyReport',
      component: () => import('@/views/member/reports/pages/daily'),
      meta: {
        menuMember: true,
        text: '일자별 리포트',
        code: 'LV080400'
      }
    },
    {
      name: 'totalList',
      path: '/reports/totalList',
      component: () => import('@/views/member/reports/pages/totalList'),
      meta: {
        menuMember: true,
        text: '거래 내역 조회',
        code: 'LV080500'
      }
    },    
    {
      name: 'companyBalance',
      path: '/reports/companyBalance',
      component: () => import('@/views/member/reports/pages/partnerBalance'),
      meta: {
        menuMember: true,
        text: '보유머니 현황',
        code: 'LV080700'
      }
    },
    {
      name: 'dailySettlement',
      path: '/reports/dailySettlement',
      component: () => import('@/views/member/reports/pages/dailySettlement'),
      meta: {
        menuMember: true,
        text: '일별 정산'
      }
    },
    {
      name: 'monthlySettlement',
      path: '/reports/monthlySettlement',
      component: () => import('@/views/member/reports/pages/monthlySettlement'),
      meta: {
        menuMember: true,
        text: '월별 정산'
      }
    }
    // {
    //   name: 'dayReport',
    //   path: '/reports/old_day',
    //   component: () => import('@/views/member/reports/pages/old_day'),
    //   meta: {
    //     menuMember: true,
    //     text: '날짜별 정산내역'
    //   }
    // },
    // {
    //   name: 'WMReport',
    //   path: '/reports/old_weekMonth',
    //   component: () => import('@/views/member/reports/pages/old_weekMonth'),
    //   meta: {
    //     menuMember: true,
    //     text: '주,월별 정산내역'
    //   }
    // }
  ]
}
