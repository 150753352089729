export const dash = {
  name: 'dash',
  path: '/dash',
  component: () => import('@/views/member/dash'),
  redirect: {
    name: 'dash'
  },
  props: true,
  meta: {
    layout: 'default',
    isActive: false,
    text: '대시보드',
    icon: 'fa fa-th-large',
    code: 'LV000000'
  },
  children: [
    {
      name: 'dashboard',
      path: '/dash',
      component: () => import('@/views/member/dash/dash'),
      meta: {
        menuMember: true,
        text: '대시보드',
        done: true,
        code: 'LV000000'
      }
    }
  ]
}
