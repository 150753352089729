export default {
  name: 'settings',
  path: '/settings',
  component: () => import('@/views/member/settings'),
  redirect: {
    name: 'Account'
  },
  meta: {
    layout: 'default',
    isActive: false,
    text: '설정',
    icon: 'fas fa-cog',
    code: 'LV090000'
  },
  children: [
    {
      name: 'AccBalance',
      path: '/settings/account/balance',
      component: () => import('@/views/member/settings/pages/account/balance'),
      meta: {
        menuMember: true,
        text: '계정현황',
        code: 'LV090101'
      }
    },
    {
      name: 'LevelBankLevel',
      path: '/settings/level/list2',
      component: () => import('@/views/member/settings/pages/level/bankLevel'),
      meta: {
        menuMember: true,
        text: '이벤트 보너스 설정',
        code: 'LV090301'
      }
    },
    {
      name: 'companyBank',
      path: '/settings/company/bank',
      component: () => import('@/views/member/settings/pages/company/bank'),
      meta: {
        menuMember: true,
        text: '은행 계좌 설정',
        code: 'LV090201'
      }
    },
    {
      name: 'LevelLevel',
      path: '/settings/level/list',
      component: () => import('@/views/member/settings/pages/level/level'),
      meta: {
        menuMember: true,
        text: '등급별 은행 설정',
        code: 'LV090403'
      }
    },
    {
      name: 'CompanyDomainType',
      path: '/settings/company/domain',
      component: () => import('@/views/member/settings/pages/company/domain.vue'),
      meta: {
        menuMember: true,
        text: '도메인설정',
        code: 'LV090203'
      }
    },
    {
      name: 'LevelDomainLevel',
      path: '/settings/level/domainLevel',
      component: () => import('@/views/member/settings/pages/level/domainLevel'),
      meta: {
        menuMember: true,
        text: '등급별 도메인 설정',
        code: 'LV090302'
      }
    },
    {
      name: 'SiteFronBanner',
      path: '/settings/site/front_banner',
      component: () => import('@/views/member/settings/pages/site/front_banner'),
      meta: {
        menuMember: true,
        text: '팝업 설정',
        code: 'LV090401'
      }
    },
    {
      name: 'maintainHistory',
      path: '/settings/maintainHistory',
      component: () => import('@/views/member/settings/pages/site/maintainHistory'),
      meta: {
        menuMember: true,
        text: '예약 점검 설정',
        code: 'LV090402'
      }
    },
    {
      name: 'SiteOptions',
      path: '/settings/site/options',
      component: () => import('@/views/member/settings/pages/site/options'),
      meta: {
        menuMember: true,
        text: '옵션 설정',
        done: true,
        code: 'LV090404'
      }
    },
    {
      name: 'CompanyBlockType',
      path: '/settings/company/blockType',
      component: () => import('@/views/member/settings/pages/company/blockType'),
      meta: {
        menuMember: true,
        text: '차단 설정',
        code: 'LV090202'
      }
    }
    /*
    {
      name: 'Account',
      path: '/settings/account',
      component: () => import('@/views/member/settings'),
      redirect: {
        name: 'AccBalance'
      },
      meta: {
        menuMember: true,
        text: '계정',
        isActive: false,
        code: 'LV090100'
      },
      children: [
        {
          name: 'AccBalance',
          path: '/settings/account/balance',
          component: () => import('@/views/member/settings/pages/account/balance'),
          meta: {
            menuMember: true,
            text: '계정현황',
            isActive: false,
            code: 'LV090101'
          }
        },
        {
          name: 'blockIp',
          path: '/settings/account/blockIp',
          component: () => import('@/views/member/settings/pages/account/blockIp'),
          meta: {
            menuMember: true,
            text: 'IP차단 목록',
            isActive: false
            // code: ''
          }
        },
        {
          name: 'Acclog',
          path: '/settings/account/log',
          component: () => import('@/views/member/settings/pages/account/log'),
          meta: {
            menuMember: true,
            text: '로그',
            isActive: false,
            code: 'LV090102'
          }
        },
        {
          name: 'loginLog',
          path: '/settings/account/loginLog',
          component: () => import('@/views/member/settings/pages/account/loginLog'),
          meta: {
            menuMember: true,
            text: '로그인 기록 조회',
            isActive: false
            // code: ''
          }
        }
      ]
    },
    {
      name: 'Company',
      path: '/settings/company',
      component: () => import('@/views/member/settings/pages/company'),
      redirect: {
        name: 'CompanyBank'
      },
      meta: {
        menuMember: true,
        text: '하부업체',
        isActive: false,
        code: 'LV090200'
      },
      children: [
        {
          name: 'companyBank',
          path: '/settings/company/bank',
          component: () => import('@/views/member/settings/pages/company/bank'),
          meta: {
            menuMember: true,
            text: '은행',
            isActive: false,
            code: 'LV090201'
          }
        },
        {
          name: 'CompanyBlockType',
          path: '/settings/company/blockType',
          component: () => import('@/views/member/settings/pages/company/blockType'),
          meta: {
            menuMember: true,
            text: '차단유형',
            isActive: false,
            code: 'LV090202'
          }
        },
        {
          name: 'CompanyDomainType',
          path: '/settings/company/domain',
          component: () => import('@/views/member/settings/pages/company/domain.vue'),
          meta: {
            menuMember: true,
            text: '도메인설정',
            isActive: false,
            code: 'LV090203'
          }
        }
      ]
    },
    {
      name: 'Level',
      path: '/settings/level',
      component: () => import('@/views/member/settings/pages/level'),
      redirect: {
        name: 'LevelBankLevel'
      },
      meta: {
        menuMember: true,
        text: '레벨별 설정',
        isActive: false,
        code: 'LV090300'
      },
      children: [
        {
          name: 'LevelBankLevel',
          path: '/settings/level/list',
          component: () => import('@/views/member/settings/pages/level/bankLevel'),
          meta: {
            menuMember: true,
            text: '계좌등급',
            isActive: false,
            code: 'LV090301'
          }
        },
        {
          name: 'LevelDomainLevel',
          path: '/settings/level/domainLevel',
          component: () => import('@/views/member/settings/pages/level/domainLevel'),
          meta: {
            menuMember: true,
            text: '도메인레벨',
            isActive: false,
            code: 'LV090302'
          }
        },
        {
          name: 'LevelLoseBonus',
          path: '/settings/level/loseBonus',
          component: () => import('@/views/member/settings/pages/level/loseBonus'),
          meta: {
            menuMember: true,
            text: '낙첨 이벤트',
            isActive: false,
            code: 'LV090303'
          }
        }
      ]
    },
    {
      name: 'Site',
      path: '/settings/site',
      component: () => import('@/views/member/settings/pages/site'),
      redirect: {
        name: 'SiteFronBanner'
      },
      meta: {
        layout: 'default',
        menuMember: true,
        text: '사이트 설정',
        isActive: false,
        code: 'LV090400'
      },
      children: [
        {
          name: 'SiteFronBanner',
          path: '/settings/site/front_banner',
          component: () => import('@/views/member/settings/pages/site/front_banner'),
          meta: {
            menuMember: true,
            text: '메인페이지 설정',
            isActive: false,
            code: 'LV090401'
          }
        },
        {
          name: 'maintainHistory',
          path: '/settings/maintainHistory',
          component: () => import('@/views/member/settings/pages/site/maintainHistory'),
          meta: {
            menuMember: true,
            text: '사이트 점검',
            code: 'LV090402'
          }
        },
        {
          name: 'SitemMintenance',
          path: '/settings/site/maintenance',
          component: () => import('@/views/member/settings/pages/site/maintenance'),
          meta: {
            menuMember: true,
            text: '사이트 점검 등록',
            isActive: false
            // code: 'LV090403'
          }
        },
        {
          name: 'SiteOptions',
          path: '/settings/site/options',
          component: () => import('@/views/member/settings/pages/site/options'),
          meta: {
            menuMember: true,
            text: '사이트 옵션',
            done: true,
            code: 'LV090404'
          }
        }
      ]
    }
    /*{
      name: 'Game',
      path: '/settings/game',
      component: () => import('@/views/member/settings/pages/game'),
      redirect: {
        name: 'CasinoSettings'
      },
      meta: {
        menuMember: true,
        text: '게임관리',
        isActive: false,
        code: 'LV090500'
      },
      children: [
        {
          name: 'CasinoSettings',
          path: '/settings/game/casino',
          component: () => import('@/views/member/bet/pages/casino/settings'),
          meta: {
            menuMember: true,
            text: '카지노게임 설정관리',
            code: 'LV090501'
          }
        },
        {
          name: 'SlotSettings',
          path: '/settings/game/slot',
          component: () => import('@/views/member/bet/pages/slot/settings'),
          meta: {
            menuMember: true,
            text: '슬롯게임 설정관리',
            code: 'LV090502'
          }
        },
        {
          name: 'SportSettings',
          path: '/settings/game/sport',
          component: () => import('@/views/member/bet/pages/sport/settings'),
          meta: {
            menuMember: true,
            text: '스포츠게임 설정관리',
            code: 'LV090503'
          }
        },
        {
          name: 'MiniSettings',
          path: '/settings/game/mini',
          component: () => import('@/views/member/bet/pages/minigame/settings'),
          meta: {
            menuMember: true,
            text: '미니게임 설정관리',
            code: 'LV090504'
          }
        }
      ]
    } */
  ]
}
