import CryptoJS from 'crypto-js'

const key = process.env.VUE_APP_CRYPTO_KEY || '25354E6B712A2'
const sha = CryptoJS.SHA256(key)

export function encrypt (data) {
  const iv = Math.random().toString(36).substring(2, 10) + Math.random().toString(36).substring(2, 10)

  return iv + CryptoJS.AES.encrypt(JSON.stringify(data), sha, {
    iv: CryptoJS.enc.Utf8.parse(iv),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  }).toString()
}

export function decrypt (data) {
  // console.log(data)
  const ivR = data.substring(0, 16)
  const content = data.substring(16)

  const result = CryptoJS.AES.decrypt(
      content,
      sha,
      {
        iv: CryptoJS.enc.Utf8.parse(ivR),
        padding: CryptoJS.pad.Pkcs7
      }
  ).toString(CryptoJS.enc.Utf8)

  return JSON.parse(result)
}
